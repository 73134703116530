<template>
  <b-row class="container">
    <div class="row-1">
      <div style="width: 70%">
        <h5>Informações e documentos úteis:</h5>

        <div class="d-flex align-items-center">
          <b-button-group class="b-btn-group">
            <b-button class="first-btn"> Informações </b-button>
            <b-button class="second-btn">
              <strong>Baixar PDF</strong>
              <i class="bx bx-cloud-download icon-download" />
            </b-button>
          </b-button-group>
          <i class="fas fa-info-circle icon-info" />
        </div>

        <div class="d-flex align-items-center">
          <b-button-group class="b-btn-group">
            <b-button class="first-btn"> Documentos </b-button>
            <b-button class="second-btn">
              <strong>Baixar Documentos</strong>
              <i class="bx bx-cloud-download icon-download" />
            </b-button>
          </b-button-group>
          <i class="fas fa-info-circle icon-info" />
        </div>
      </div>

      <div class="box-button-comments">
        <span>Ver todas as mensagens</span>
        <b-button variant="primary">
          <i class="far fa-comment-dots" />
          <span style="margin-left: 10px">{{ 0 }}</span>
        </b-button>
      </div>
    </div>

    <hr style="width: 100%; background: #eee" />

    <div class="row-2">
      <h5>Link da fornecedora:</h5>
      <div class="d-flex">
        <b-button class="second-btn-row-2">
          www.url_site_da_fornecedora…
        </b-button>
        <i class="fas fa-info-circle icon-info" />
      </div>
    </div>

    <hr style="width: 100%; background: #eee" />

    <div class="row-3">
      <h5>CONTRATOS / APÓLICES</h5>

      <div class="d-flex">
        <b-form-file
          browse-text="Selecionar"
          placeholder="Nome do arquivo..."
          drop-placeholder="Solte aqui..."
          v-model="file"
        />

        <b-button
          class="ml-2"
          variant="primary"
          @click="() => handleUploadFile()"
        >
          Enviar
        </b-button>
      </div>

      <div
        v-if="item.diagnostico_apolice && item.diagnostico_apolice.download_url"
        class="mt-3 d-flex justify-content-between align-items-center"
      >
        <div>
          <p class="mb-0">{{ item.diagnostico_apolice.nome_original }}</p>
          <a :href="item.diagnostico_apolice.download_url" target="_blank">
            Visualizar
          </a>
        </div>

        <b-button pill variant="danger" @click="() => handleDeleteFile()">
          <i class="fas fa-trash-alt" />
        </b-button>
      </div>
    </div>
  </b-row>
</template>

<script>
import { cloneObject } from '@/helpers';
import {
  uploadDiagnosisPolicy,
  uploadPolicyFile,
  deleteDiagnosisPolicy
} from '@/services/requests/diagnosis';

export default {
  name: 'InputsDownloads',
  props: {
    diagnosis: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      file: null
    };
  },
  methods: {
    async handleUploadFile() {
      if (!this.file) return;

      if (!this.item.documento_apolice) {
        const data = {
          diagnostico_id: this.diagnosis.diagnostico_id,
          titulo: this.item.titulo,
          comentario_especialista: null,
          diagnostico_produto_item_id: this.item.diagnostico_produto_item_id
        };

        try {
          const response = await uploadDiagnosisPolicy(data);
          const diagnosticoApolice = response.data.result.data;

          this.$set(this.item, 'diagnostico_apolice', diagnosticoApolice);
        } catch {
          this.$store.state.modal.isOpenModalErrorRequest = true;
          return;
        }
      }

      uploadPolicyFile(this.item.diagnostico_apolice, this.file)
        .then((res) => {
          const apolice = this.item.diagnostico_apolice;

          const updatedApolice = {
            ...cloneObject(apolice),
            ...res.data.result.data
          };

          this.$set(this.item, 'diagnostico_apolice', updatedApolice);
          this.file = null;
        })
        .catch((e2) => {
          console.log(e2);
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },
    handleDeleteFile() {
      if (!confirm('Deseja realmente excluir este arquivo?'));

      deleteDiagnosisPolicy(
        this.item.diagnostico_apolice.diagnostico_apolice_id
      )
        .then(() => {
          this.$set(this.item, 'diagnostico_apolice', null);
        })
        .catch((error) => {
          let errorMessage = 'Houve um erro ao salvar as opções';

          if (error.response) {
            if (
              error.response.data.statusCode &&
              error.response.data.statusCode !== 200
            ) {
              errorMessage = error.response.data.statusMessage;
            }

            if (
              error.response.data.message &&
              !error.response.data.message.diagnostico_produto_item_opcoes
            ) {
              errorMessage = 'Todos os valores são obrigatórios';
            }
          }

          alert(errorMessage);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
}

.row-1 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.box-button-comments {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 17%;
  text-align: center;

  color: var(--primary);
}

.icon-info {
  margin-left: 1rem;
  font-size: 1.4rem;
  color: var(--primary);
  margin-top: 1rem;
}

.icon-download {
  margin-left: 1rem;
  font-size: 1.6rem;
  color: #fff;
}

.b-btn-group {
  margin-top: 1rem;
  width: 80%;

  strong {
    font-size: 90%;
  }
}

.first-btn {
  background: var(--primary);
  border: none;
  border-radius: 5px;
  width: 50%;
  text-align: initial;
}

.second-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--dark-primary);
  border: none;
  border-radius: 5px;
  width: 50%;
  text-align: initial;
}

.row-2 {
  display: flex;
  flex-direction: column;
  width: 100%;

  .second-btn-row-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--dark-primary);
    border: none;
    border-radius: 5px;
    width: 13rem;
    text-align: initial;
    width: 61%;
  }
}

.row-3 {
  display: flex;
  flex-direction: column;
  width: 100%;

  .first-btn-row-3 {
    display: flex;
    align-items: center;
    background: var(--primary);
    border: none;
    border-radius: 5px;
    width: 13rem;
    text-align: initial;
    width: 61%;

    strong {
      margin-left: 0.5rem;
    }
  }
}
</style>
