<template>
  <div>
    <div class="row" style="position: relative">
      <b-col class="container-left">
        <h5>{{ item.titulo }}</h5>
        <span
          v-if="item.titulo === 'Investimento' || item.grupo === 'Real estate'"
          >{{ item.descricao }}</span
        >

        <div
          v-if="
            pageNumber === 2 &&
            item.tipo_tabela === 1 &&
            (item.resposta_cliente === 1 || item.resposta_cliente === 3)
          "
          :class="
            item.resposta_cliente === 1
              ? 'approved'
              : item.resposta_cliente === 3
              ? 'refused'
              : ''
          "
        >
          <span v-if="item.resposta_cliente === 1">
            OPÇÃO
            {{
              item.opcao_selecionada_necessario
                ? 'VALOR SUGERIDO'
                : item.diagnostico_produto_item_opcoes.findIndex(
                    (option) =>
                      option.diagnostico_produto_item_opcao_id ===
                      item.opcao_selecionada_id
                  ) + 1
            }}
            - APROVADA
          </span>

          <span v-if="item.resposta_cliente === 3"> PROPOSTAS RECUSADAS </span>
        </div>

        <div
          v-if="pageNumber === 1 && item.tipo_tabela === 1"
          class="optionsAlreadyRegister"
        >
          {{ item.diagnostico_produto_item_opcoes.length }}
          OPÇÕES FORAM CADASTRADAS
        </div>

        <div
          v-if="[1, 2].includes(pageNumber) && item.tipo_tabela === 2"
          class="optionsAlreadyRegister"
        >
          CADASTRE AS OPÇÕES E COMPLETE A TABELA DE COTAÇÕES
        </div>

        <div
          class="box-alert-client-already-has-product"
          v-if="customerAlreadyHasProduct"
        >
          <i class="bx bx-error" />
          <span>O cliente já possui este produto</span>

          <b-button @click="verifyItens()" variant="primary">
            VERIFICAR ARQUIVO
          </b-button>
        </div>

        <b-row v-if="[1, 7].includes(diagnosisProduct.produto_diagnostico_id)">
          <b-col cols="12">
            <div
              v-if="
                item.titulo !== 'Câmbio' &&
                item.titulo !== 'Previdência' &&
                item.titulo !== 'Real Estate' &&
                item.grupo !== 'Direcionamento ao especialista'
              "
              class="text-validation"
            >
              ANTES DE CONCLUIR, Preencha os campos abaixo com os dados e
              valores da cotação:
            </div>
          </b-col>
        </b-row>

        <b-col md="6" v-if="checkingItems">
          <table>
            <tr>
              <th>Seguro</th>
              <th></th>
            </tr>
            <tr v-for="(seguro, index) in item.seguros" :key="index">
              <td>{{ seguro.nome }}</td>
              <td v-if="seguro.download_url">
                <p class="mb-0">{{ seguro.apolice_nome }}</p>
                <a :href="seguro.download_url" target="_blank"> Visualizar </a>
              </td>
              <td v-else />
            </tr>
          </table>
        </b-col>

        <b-col cols="12" class="mt-3" v-if="[2, 3].includes(item.id_campo)">
          <table class="table" style="max-width: 400px">
            <tbody>
              <tr>
                <th
                  class="border border-dark"
                  style="background-color: var(--light-grey-2)"
                >
                  Despesas com o prazo
                </th>
                <td class="border border-dark text-center">
                  <span v-if="item.despesa_prazo">
                    {{ item.despesa_prazo }} anos
                  </span>
                  <span v-else> - </span>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>

        <b-col cols="12" v-if="item.tipo_tabela === 1">
          <table style="margin-top: 2rem" class="table">
            <tr>
              <th>ITENS</th>

              <th colspan="2" style="background: #e8e8ea9f">
                <b-button
                  pill
                  variant="dark-primary"
                  class="d-flex justify-content-center align-items-center ml-auto"
                  style="
                    width: 2rem;
                    height: 2rem;
                    border: none;
                    border-radius: 50%;
                  "
                  @click="handleAddOption()"
                  v-if="pageNumber === 1"
                >
                  <i class="bx bx-plus" style="font-size: 1rem" />
                </b-button>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  '#556ee6'
                };`"
                colspan="2"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <EditButton
                    :active="true"
                    @click.native="() => handleEditQuotation()"
                    v-if="pageNumber === 1 && !editMode"
                  />
                  <div v-else />

                  <span style="margin-right: 1rem">
                    OPÇÃO {{ currentOptionIndex + 1 }}
                  </span>

                  <TrashButton
                    :click="() => handleDeleteOption()"
                    :active="true"
                    v-if="pageNumber === 1"
                  />
                  <span v-else />
                </div>
              </th>
            </tr>

            <tr>
              <th></th>

              <th
                style="background: #e8e8ea9f; color: #495057"
                class="text-center"
              >
                VALOR NECESSÁRIO
              </th>

              <th
                style="background: #e8e8ea9f; color: #495057"
                class="text-center"
              >
                VALOR ATUAL
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario &&
                  '#556ee6'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                <b-button
                  size="sm"
                  variant="light"
                  class="float-left"
                  style="background-color: #eff2f7"
                  :disabled="!hasPrevOption"
                  @click="handlePrevOption()"
                >
                  <i class="fas fa-arrow-left" />
                </b-button>

                VALOR SUGERIDO
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario &&
                  '#556ee6'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                VALOR AJUSTADO

                <b-button
                  size="sm"
                  variant="light"
                  class="float-right"
                  style="background-color: #eff2f7"
                  :disabled="!hasNextOption"
                  @click="handleNextOption()"
                >
                  <i class="fas fa-arrow-right" />
                </b-button>
              </th>
            </tr>

            <tr>
              <td>
                <span> 1. Capital Segurado </span>
              </td>
              <td :style="`background: #e8e8ea9f;`">
                R$ {{ item.necessidades | decimal }}
              </td>

              <td :style="`background: #e8e8ea9f;`">
                R$ {{ item.valor_atual | decimal }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                R$ {{ currentOption.capital_segurado_sugerido | decimal }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.capital_segurado"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.capital_segurado | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>2. Seguradora Sugerida</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroPrincipal">
                  {{ seguroPrincipal.seguradora }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.seguradora_sugerido"
                  :options="insurersOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.seguradora_sugerido }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.seguradora"
                  :options="insurersOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.seguradora }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>3. Nome da solução</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroPrincipal">
                  {{ seguroPrincipal.tipo_seguro }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.solucao_sugerido"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.solucao_sugerido }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.solucao"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.solucao }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>4. Período de cobertura</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`"></td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_cobertura_sugerido"
                  :options="
                    isGestaoRiscoMorte
                      ? periodoCoberturaMorte
                      : isInvalidez
                      ? periodoCoberturaInvalidez
                      : periodoCoberturaVida
                  "
                  @change="
                    (value) => handleChangePeriodoCoberturaSugerido(value)
                  "
                  v-if="editMode"
                />
                <span v-else>
                  {{
                    currentOption.periodo_cobertura_sugerido | periodoCobertura
                  }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_cobertura"
                  :options="
                    isGestaoRiscoMorte
                      ? periodoCoberturaMorte
                      : periodoCoberturaVida
                  "
                  @change="(value) => handleChangePeriodoCobertura(value)"
                  v-if="editMode"
                />
                <span v-else>
                  {{ currentOption.periodo_cobertura | periodoCobertura }}
                </span>
              </td>
            </tr>

            <tr v-if="isGestaoRiscoMorte">
              <td>
                <span>5. Período de pagamento</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`"></td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_pagamento_sugerido"
                  :options="periodoCoberturaMorte"
                  v-if="editMode"
                />
                <span v-else>
                  {{ currentOption.periodo_pagamento_sugerido }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_pagamento"
                  :options="periodoCoberturaMorte"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.periodo_pagamento }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>6. Prêmio mensal</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroPrincipal">
                  R$ {{ seguroPrincipal.valor_mensal | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_mensal_sugerido"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.premio_mensal_sugerido | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_mensal"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.premio_mensal | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>7. Prêmio anual</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroPrincipal">
                  R$ {{ (seguroPrincipal.valor_mensal * 12) | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_anual_sugerido"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.premio_anual_sugerido | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_anual"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.premio_anual | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div>
                  <b-form-file
                    size="sm"
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    @input="
                      () => handleFileUpload(currentOption, currentOptionIndex)
                    "
                    v-model="currentOption.file"
                  />

                  <div>{{ currentOption.nome_original }}</div>
                </div>

                <div
                  v-if="currentOption.download_url"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="currentOption.download_url" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    @click="() => handleDeleteFile()"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </td>

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div>
                  <b-form-file
                    size="sm"
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    @input="
                      () => handleFile2Upload(currentOption, currentOptionIndex)
                    "
                    v-model="currentOption.file_2"
                  />

                  <div>{{ currentOption.nome_original_2 }}</div>
                </div>

                <div
                  v-if="currentOption.download_url_2"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="currentOption.download_url_2" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    @click="() => handleDeleteFile2()"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td
                style="background: #e8e8ea9f"
                colspan="2"
                v-if="currentOption"
              >
                <b-button
                  @click="() => handleSaveQuotation()"
                  v-if="editMode"
                  variant="dark-primary"
                  class="d-flex align-items-center"
                >
                  <i
                    class="bx bx-plus"
                    style="font-size: 1rem; margin-right: 10px"
                  />
                  SALVAR
                </b-button>
              </td>
            </tr>
          </table>
        </b-col>

        <b-col
          cols="12"
          v-if="item.tipo_tabela === 1 && item.id_campo === 1 && false"
        >
          <table style="margin-top: 2rem" class="table">
            <tr>
              <th>ITENS</th>

              <th colspan="2" style="background: #e8e8ea9f">
                <b-button
                  pill
                  variant="dark-primary"
                  class="d-flex justify-content-center align-items-center ml-auto"
                  style="
                    width: 2rem;
                    height: 2rem;
                    border: none;
                    border-radius: 50%;
                  "
                  @click="handleAddOption()"
                  v-if="pageNumber === 1"
                >
                  <i class="bx bx-plus" style="font-size: 1rem" />
                </b-button>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario &&
                  '#556ee6'
                };`"
                colspan="2"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <EditButton
                    :active="true"
                    @click.native="() => handleEditQuotation()"
                    v-if="pageNumber === 1 && !editMode"
                  />
                  <div v-else />

                  <span style="margin-right: 1rem">
                    OPÇÃO {{ currentOptionIndex + 1 }}
                  </span>

                  <TrashButton
                    :click="() => handleDeleteOption()"
                    :active="true"
                    v-if="pageNumber === 1"
                  />
                  <span v-else />
                </div>
              </th>
            </tr>

            <tr>
              <th>VIDA</th>

              <th
                style="background: #e8e8ea9f; color: #495057"
                class="text-center"
              >
                VALOR NECESSÁRIO
              </th>

              <th
                style="background: #e8e8ea9f; color: #495057"
                class="text-center"
              >
                VALOR ATUAL
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario &&
                  '#556ee6'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                <b-button
                  size="sm"
                  variant="light"
                  class="float-left"
                  style="background-color: #eff2f7"
                  :disabled="!hasPrevOption"
                  @click="handlePrevOption()"
                >
                  <i class="fas fa-arrow-left" />
                </b-button>

                VALOR SUGERIDO
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario &&
                  '#556ee6'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                VALOR AJUSTADO

                <b-button
                  size="sm"
                  variant="light"
                  class="float-right"
                  style="background-color: #eff2f7"
                  :disabled="!hasNextOption"
                  @click="handleNextOption()"
                >
                  <i class="fas fa-arrow-right" />
                </b-button>
              </th>
            </tr>

            <tr>
              <td>
                <span> 1. Capital Segurado </span>
              </td>
              <td :style="`background: #e8e8ea9f;`">
                R$ {{ item.necessidades | decimal }}
              </td>

              <td :style="`background: #e8e8ea9f;`">
                R$ {{ item.valor_atual | decimal }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                R$ {{ currentOption.capital_segurado_sugerido | decimal }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.capital_segurado"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.capital_segurado | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>2. Seguradora Sugerida</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroVida">
                  {{ seguroVida.seguradora }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.seguradora_sugerido"
                  :options="insurersOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.seguradora_sugerido }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.seguradora"
                  :options="insurersOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.seguradora }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>3. Nome da solução</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroVida">
                  {{ seguroVida.tipo_seguro }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.solucao_sugerido"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.solucao_sugerido }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.solucao"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.solucao }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>4. Período de cobertura</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`"></td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_cobertura_sugerido"
                  :options="
                    isGestaoRiscoMorte
                      ? periodoCoberturaMorte
                      : periodoCoberturaVida
                  "
                  @change="
                    (value) => handleChangePeriodoCoberturaSugerido(value)
                  "
                  v-if="editMode"
                />
                <span v-else>
                  {{
                    currentOption.periodo_cobertura_sugerido | periodoCobertura
                  }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_cobertura"
                  :options="
                    isGestaoRiscoMorte
                      ? periodoCoberturaMorte
                      : periodoCoberturaVida
                  "
                  @change="(value) => handleChangePeriodoCobertura(value)"
                  v-if="editMode"
                />
                <span v-else>
                  {{ currentOption.periodo_cobertura | periodoCobertura }}
                </span>
              </td>
            </tr>

            <tr v-if="isGestaoRiscoMorte">
              <td>
                <span>5. Período de pagamento</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`"></td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_pagamento_sugerido"
                  :options="periodoCoberturaMorte"
                  v-if="editMode"
                />
                <span v-else>{{
                  currentOption.periodo_pagamento_sugerido
                }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.periodo_pagamento"
                  :options="periodoCoberturaMorte"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.periodo_pagamento }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>6. Prêmio mensal</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroVida">
                  R$ {{ seguroVida.valor_mensal | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_mensal_sugerido"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.premio_mensal_sugerido | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_mensal"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.premio_mensal | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>7. Prêmio anual</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroVida">
                  R$ {{ (seguroVida.valor_mensal * 12) | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_anual_sugerido"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.premio_anual_sugerido | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.premio_anual"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.premio_anual | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div>
                  <b-form-file
                    size="sm"
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    @input="
                      () => handleFileUpload(currentOption, currentOptionIndex)
                    "
                    v-model="currentOption.file"
                  />

                  <div>{{ currentOption.nome_original }}</div>
                </div>

                <div
                  v-if="currentOption.download_url"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="currentOption.download_url" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    @click="() => handleDeleteFile()"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </td>

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div>
                  <b-form-file
                    size="sm"
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    @input="
                      () => handleFile2Upload(currentOption, currentOptionIndex)
                    "
                    v-model="currentOption.file_2"
                  />

                  <div>{{ currentOption.nome_original_2 }}</div>
                </div>

                <div
                  v-if="currentOption.download_url_2"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="currentOption.download_url_2" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    @click="() => handleDeleteFile2()"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td
                style="background: #e8e8ea9f"
                colspan="2"
                v-if="currentOption"
              >
                <b-button
                  @click="() => handleSaveQuotation()"
                  v-if="editMode"
                  variant="dark-primary"
                  class="d-flex align-items-center"
                >
                  <i
                    class="bx bx-plus"
                    style="font-size: 1rem; margin-right: 10px"
                  />
                  SALVAR
                </b-button>
              </td>
            </tr>
          </table>

          <table style="margin-top: 2rem" class="table">
            <tr>
              <th>ITENS</th>

              <th colspan="2" style="background: #e8e8ea9f">
                <b-button
                  pill
                  variant="dark-primary"
                  class="d-flex justify-content-center align-items-center ml-auto"
                  style="
                    width: 2rem;
                    height: 2rem;
                    border: none;
                    border-radius: 50%;
                  "
                  @click="handleAddOption()"
                  v-if="pageNumber === 1"
                >
                  <i class="bx bx-plus" style="font-size: 1rem" />
                </b-button>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  item.opcao_selecionada_necessario &&
                  '#556ee6'
                };`"
                colspan="2"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <EditButton
                    :active="true"
                    @click.native="() => handleEditQuotation()"
                    v-if="pageNumber === 1 && !editMode"
                  />
                  <div v-else />

                  <span style="margin-right: 1rem">
                    OPÇÃO {{ currentOptionIndex + 1 }}
                  </span>

                  <TrashButton
                    :click="() => handleDeleteOption()"
                    :active="true"
                    v-if="pageNumber === 1"
                  />
                  <span v-else />
                </div>
              </th>
            </tr>

            <tr>
              <th>FUNERAL</th>

              <th
                style="background: #e8e8ea9f; color: #495057"
                class="text-center"
              >
                VALOR NECESSÁRIO
              </th>

              <th
                style="background: #e8e8ea9f; color: #495057"
                class="text-center"
              >
                VALOR ATUAL
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  item.fun_opcao_selecionada_necessario &&
                  '#556ee6'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                <b-button
                  size="sm"
                  variant="light"
                  class="float-left"
                  style="background-color: #eff2f7"
                  :disabled="!hasPrevOption"
                  @click="handlePrevOption()"
                >
                  <i class="fas fa-arrow-left" />
                </b-button>

                VALOR SUGERIDO
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_idw &&
                  !item.fun_opcao_selecionada_necessario &&
                  '#556ee6'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                VALOR AJUSTADO

                <b-button
                  size="sm"
                  variant="light"
                  class="float-right"
                  style="background-color: #eff2f7"
                  :disabled="!hasNextOption"
                  @click="handleNextOption()"
                >
                  <i class="fas fa-arrow-right" />
                </b-button>
              </th>
            </tr>

            <tr>
              <td>
                <span> 1. Capital Segurado </span>
              </td>
              <td :style="`background: #e8e8ea9f;`">
                <span v-if="itemFuneral">
                  R$ {{ itemFuneral.necessidades | decimal }}
                </span>
              </td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="itemFuneral">
                  R$ {{ itemFuneral.valor_atual | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                R$ {{ currentOption.fun_capital_segurado_sugerido | decimal }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  !item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.fun_capital_segurado"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.fun_capital_segurado | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>2. Seguradora Sugerida</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroFuneral">
                  {{ seguroFuneral.seguradora }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.fun_seguradora_sugerido"
                  :options="insurersOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.fun_seguradora_sugerido }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  !item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.fun_seguradora"
                  :options="insurersOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.fun_seguradora }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>3. Nome da solução</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroFuneral">
                  {{ seguroFuneral.tipo_seguro }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.fun_solucao_sugerido"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.fun_solucao_sugerido }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  !item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.fun_solucao"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.fun_solucao }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>4. Período de cobertura</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`"></td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.fun_periodo_cobertura_sugerido"
                  :options="periodoCoberturaVida"
                  @change="
                    (value) => handleChangeFunPeriodoCoberturaSugerido(value)
                  "
                  v-if="editMode"
                />
                <span v-else>
                  {{
                    currentOption.fun_periodo_cobertura_sugerido
                      | periodoCobertura
                  }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  !item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.fun_periodo_cobertura"
                  :options="periodoCoberturaVida"
                  @change="(value) => handleChangeFunPeriodoCobertura(value)"
                  v-if="editMode"
                />
                <span v-else>
                  {{ currentOption.fun_periodo_cobertura | periodoCobertura }}
                </span>
              </td>
            </tr>

            <tr v-if="isGestaoRiscoMorte">
              <td>
                <span>5. Período de pagamento</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`"></td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.fun_periodo_pagamento_sugerido"
                  :options="periodoCoberturaVida"
                  v-if="editMode"
                />
                <span v-else>{{
                  currentOption.fun_periodo_pagamento_sugerido
                }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  !item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.fun_periodo_pagamento"
                  :options="periodoCoberturaVida"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.fun_periodo_pagamento }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>6. Prêmio mensal</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroFuneral">
                  R$ {{ seguroFuneral.valor_mensal | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.fun_premio_mensal_sugerido"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.fun_premio_mensal_sugerido | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  !item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.fun_premio_mensal"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.fun_premio_mensal | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>7. Prêmio anual</span>
              </td>
              <td :style="`background: #e8e8ea9f;`"></td>

              <td :style="`background: #e8e8ea9f;`">
                <span v-if="seguroFuneral">
                  R$ {{ (seguroFuneral.valor_mensal * 12) | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.fun_premio_anual_sugerido"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.fun_premio_anual_sugerido | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.fun_opcao_selecionada_id &&
                  !item.fun_opcao_selecionada_necessario
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.fun_premio_anual"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.fun_premio_anual | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div>
                  <b-form-file
                    size="sm"
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    @input="
                      () => handleFileUpload(currentOption, currentOptionIndex)
                    "
                    v-model="currentOption.file"
                  />

                  <div>{{ currentOption.nome_original }}</div>
                </div>

                <div
                  v-if="currentOption.download_url"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="currentOption.download_url" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    @click="() => handleDeleteFile()"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </td>

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div>
                  <b-form-file
                    size="sm"
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    @input="
                      () => handleFile2Upload(currentOption, currentOptionIndex)
                    "
                    v-model="currentOption.file_2"
                  />

                  <div>{{ currentOption.nome_original_2 }}</div>
                </div>

                <div
                  v-if="currentOption.download_url_2"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="currentOption.download_url_2" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    @click="() => handleDeleteFile2()"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td
                style="background: #e8e8ea9f"
                colspan="2"
                v-if="currentOption"
              >
                <b-button
                  @click="() => handleSaveQuotation()"
                  v-if="editMode"
                  variant="dark-primary"
                  class="d-flex align-items-center"
                >
                  <i
                    class="bx bx-plus"
                    style="font-size: 1rem; margin-right: 10px"
                  />
                  SALVAR
                </b-button>
              </td>
            </tr>
          </table>
        </b-col>

        <b-col cols="12" v-if="item.tipo_tabela === 3">
          <table style="margin-top: 2rem" class="table">
            <tr>
              <th></th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  '#556ee6'
                };`"
                colspan="2"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div />

                  <span>PLANO ATUAL</span>

                  <b-button
                    pill
                    variant="dark-primary"
                    class="d-flex justify-content-center align-items-center"
                    style="
                      width: 2rem;
                      height: 2rem;
                      border: none;
                      border-radius: 50%;
                    "
                    @click="handleAddOption()"
                    v-if="pageNumber === 1"
                  >
                    <i class="bx bx-plus" style="font-size: 1rem" />
                  </b-button>
                </div>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  '#556ee6'
                };`"
                colspan="2"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <EditButton
                    :active="true"
                    @click.native="() => handleEditQuotation()"
                    v-if="pageNumber === 1 && !editMode"
                  />
                  <div v-else />

                  <span style="margin-right: 1rem">
                    OPÇÃO {{ currentOptionIndex + 1 }}
                  </span>

                  <TrashButton
                    :click="() => handleDeleteOption()"
                    :active="true"
                    v-if="pageNumber === 1"
                  />
                  <span v-else />
                </div>
              </th>
            </tr>

            <tr>
              <th></th>

              <th style="background: #e8e8ea9f" class="text-center">
                <b-button
                  size="sm"
                  variant="light"
                  class="float-left"
                  style="background-color: #eff2f7"
                  :disabled="!hasPrevOption"
                  @click="handlePrevOption()"
                  v-if="currentOption"
                >
                  <i class="fas fa-arrow-left" />
                </b-button>

                TITULAR
              </th>

              <th style="background: #e8e8ea9f" class="text-center">
                DEPENDENTES NÃO INCLUSOS
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes &&
                  '#556ee6'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                TITULAR
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes &&
                  '#556ee6'
                };`"
                class="text-center"
                v-if="currentOption"
              >
                DEPENDENTES NÃO INCLUSOS
                <b-button
                  size="sm"
                  variant="light"
                  class="float-right"
                  style="background-color: #eff2f7"
                  :disabled="!hasNextOption"
                  @click="handleNextOption()"
                >
                  <i class="fas fa-arrow-right" />
                </b-button>
              </th>
            </tr>

            <tr>
              <td>
                <span>1. Pessoa física ou jurídica</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.tipo_pessoa }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.tipo_pessoa }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.tipo_pessoa"
                  :options="tipoPessoaSaudeOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.tipo_pessoa }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.dep_tipo_pessoa"
                  :options="tipoPessoaSaudeOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.dep_tipo_pessoa }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>2. Operadora</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.operadora }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.operadora }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.operadora"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.operadora }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.dep_operadora"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.dep_operadora }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>3. Estado</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.estado }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.estado }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.estado"
                  :options="stateOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.estado }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.dep_estado"
                  :options="stateOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.dep_estado }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>4. Cidade</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.cidade }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.cidade }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.cidade"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.cidade }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.dep_cidade"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.dep_cidade }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>5. Nome de produto</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.nome_produto }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.nome_produto }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.nome_produto"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.nome_produto }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.dep_nome_produto"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.dep_nome_produto }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>6. Tipo de cobertura</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.tipo_cobertura }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.tipo_cobertura }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.tipo_cobertura"
                  :options="tipoCoberturaOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.tipo_cobertura }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.dep_tipo_cobertura"
                  :options="tipoCoberturaOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.dep_tipo_cobertura }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>7. Acomodação</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.acomodacao }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.acomodacao }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.acomodacao"
                  :options="acomodacaoOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.acomodacao }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.dep_acomodacao"
                  :options="acomodacaoOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.dep_acomodacao }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>8. Hospital de preferência</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.possui_preferencia }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.possui_preferencia }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.possui_preferencia"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.possui_preferencia }}</span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.dep_possui_preferencia"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.dep_possui_preferencia }}</span>
              </td>
            </tr>

            <tr>
              <td>9. Vidas</td>
              <td colspan="4" style="background-color: #e8e8ea9f" />
            </tr>

            <tr v-for="(faixa, index) in faixasIdadeVidasSaude" :key="index">
              <td class="pl-4">{{ faixa.title }}</td>

              <td
                style="background: #e8e8ea9f; color: #495057"
                class="text-center"
              >
                {{ faixa.quantidadeTitular || '-' }}
              </td>

              <td
                style="background: #e8e8ea9f; color: #495057"
                class="text-center"
              >
                {{ faixa.quantidadeConjuge || '-' }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
                class="text-center"
              >
                {{ faixa.quantidadeTitular || '-' }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
                class="text-center"
              >
                {{ faixa.quantidadeConjuge || '-' }}
              </td>
            </tr>

            <tr>
              <td>
                <span>10. Valor mensal</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$ {{ diagnosis.pessoa.saude.valor_mensal | decimal }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  R$ {{ saudeDependente.valor_mensal | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_mensal"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.valor_mensal | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.dep_valor_mensal"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.dep_valor_mensal | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>11. Valor de reembolso</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$ {{ diagnosis.pessoa.saude.valor_reembolso | decimal }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  R$ {{ saudeDependente.valor_reembolso | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_reembolso"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.valor_reembolso | decimal }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.dep_valor_reembolso"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.dep_valor_reembolso | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>12. Co-participação</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ diagnosis.pessoa.saude.coparticipacao ? 'Sim' : 'Não' }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                <span v-if="saudeDependente">
                  {{ saudeDependente.coparticipacao ? 'Sim' : 'Não' }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.coparticipacao"
                  :options="yesOrNotOptions"
                  v-if="editMode"
                />
                <span v-else>
                  {{ currentOption.coparticipacao ? 'Sim' : 'Não' }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.dep_coparticipacao"
                  :options="yesOrNotOptions"
                  v-if="editMode"
                />
                <span v-else>
                  {{ currentOption.dep_coparticipacao ? 'Sim' : 'Não' }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>13. Validade da proposta</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ item.diagnostico_produto_item_tabela_itens[9].coluna_valor }}
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{ item.diagnostico_produto_item_tabela_itens[9].coluna_valor }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  !item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.validade_proposta"
                  v-if="editMode"
                />
                <span v-else>
                  {{ currentOption.validade_proposta }}
                </span>
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  item.opcao_selecionada_dependentes
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.dep_validade_proposta"
                  v-if="editMode"
                />
                <span v-else>
                  {{ currentOption.dep_validade_proposta }}
                </span>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div>
                  <b-form-file
                    size="sm"
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    @input="
                      () => handleFileUpload(currentOption, currentOptionIndex)
                    "
                    v-model="currentOption.file"
                  />

                  <div>{{ currentOption.nome_original }}</div>
                </div>

                <div
                  v-if="currentOption.download_url"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="currentOption.download_url" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    @click="() => handleDeleteFile()"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </td>

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div>
                  <b-form-file
                    size="sm"
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    @input="
                      () => handleFile2Upload(currentOption, currentOptionIndex)
                    "
                    v-model="currentOption.file_2"
                  />

                  <div>{{ currentOption.nome_original_2 }}</div>
                </div>

                <div
                  v-if="currentOption.download_url_2"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="currentOption.download_url_2" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    @click="() => handleDeleteFile2()"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td
                style="background: #e8e8ea9f"
                colspan="2"
                v-if="currentOption"
              >
                <b-button
                  @click="() => handleSaveQuotation()"
                  v-if="editMode"
                  variant="dark-primary"
                  class="d-flex align-items-center"
                >
                  <i
                    class="bx bx-plus"
                    style="font-size: 1rem; margin-right: 10px"
                  />
                  SALVAR
                </b-button>
              </td>
            </tr>
          </table>
        </b-col>

        <b-col cols="12" v-if="item.tipo_tabela === 5">
          <table style="margin-top: 2rem" class="table">
            <tr>
              <th></th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  '#556ee6'
                };`"
              >
                <b-button
                  size="sm"
                  variant="light"
                  class="float-left"
                  style="background-color: #eff2f7"
                  :disabled="!hasPrevOption"
                  @click="handlePrevOption()"
                  v-if="currentOption"
                >
                  <i class="fas fa-arrow-left" />
                </b-button>

                <div class="d-flex align-items-center justify-content-between">
                  <div />

                  <span>ATUAL</span>

                  <b-button
                    pill
                    variant="dark-primary"
                    class="d-flex justify-content-center align-items-center"
                    style="
                      width: 2rem;
                      height: 2rem;
                      border: none;
                      border-radius: 50%;
                    "
                    @click="handleAddOption()"
                    v-if="!isProdutoPatrimonio && pageNumber === 1"
                  >
                    <i class="bx bx-plus" style="font-size: 1rem" />
                  </b-button>
                </div>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  '#556ee6'
                };`"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <EditButton
                    :active="true"
                    @click.native="() => handleEditQuotation()"
                    v-if="!isProdutoPatrimonio && pageNumber === 1 && !editMode"
                  />
                  <div v-else />

                  <span style="margin-right: 1rem">
                    OPÇÃO {{ currentOptionIndex + 1 }}
                  </span>

                  <div class="d-flex align-items-center">
                    <TrashButton
                      :click="() => handleDeleteOption()"
                      :active="true"
                      v-if="!isProdutoPatrimonio && pageNumber === 1"
                    />
                    <span v-else />

                    <b-button
                      size="sm"
                      variant="light"
                      class="float-right ml-3"
                      style="background-color: #eff2f7"
                      :disabled="!hasNextOption"
                      @click="handleNextOption()"
                    >
                      <i class="fas fa-arrow-right" />
                    </b-button>
                  </div>
                </div>
              </th>
            </tr>

            <tr>
              <td>
                <span>1. Nome do bem</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[0].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.nome_bem"
                  v-if="!isProdutoPatrimonio && editMode"
                />
                <span v-else>{{ currentOption.nome_bem }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>2. Tipo</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[1].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.tipo"
                  :options="tipoBemOptions"
                  v-if="!isProdutoPatrimonio && editMode"
                />
                <span v-else>{{ currentOption.tipo }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>3. Valor do bem</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[2].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_bem"
                  v-if="!isProdutoPatrimonio && editMode"
                />
                <span v-else> R$ {{ currentOption.valor_bem | decimal }} </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>4. Valor financiado</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[3].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_financiado"
                  v-if="!isProdutoPatrimonio && editMode"
                />
                <span v-else>
                  R$ {{ currentOption.valor_financiado | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>5. Parcela mensal</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[4].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_mensal"
                  v-if="!isProdutoPatrimonio && editMode"
                />
                <span v-else>
                  R$ {{ currentOption.valor_mensal | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>6. Quantas parcelas faltam</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[5].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-mask="'###'"
                  v-model="currentOption.parcelas_restantes"
                  v-if="!isProdutoPatrimonio && editMode"
                />
                <span v-else>{{ currentOption.parcelas_restantes }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>7. Forma de construção</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[6].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.forma_construcao"
                  :options="formaConstrucaoOptions"
                  v-if="!isProdutoPatrimonio && editMode"
                />
                <span v-else>{{ currentOption.forma_construcao }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>8. Taxa de correção</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[7].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.taxa_correcao"
                  :options="indiceCorrecaoOptions"
                  v-if="!isProdutoPatrimonio && editMode"
                />
                <span v-else>{{ currentOption.taxa_correcao }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>9. Operadora</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[8].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.operadora"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.operadora }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>10. Valor de entrada ou lance</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[9].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_entrada"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.valor_entrada | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>11. Validade da proposta</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[10].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.validade_proposta"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.validade_proposta }}</span>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" />

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div>
                  <b-form-file
                    size="sm"
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    @input="
                      () => handleFileUpload(currentOption, currentOptionIndex)
                    "
                    v-model="currentOption.file"
                  />

                  <div>{{ currentOption.nome_original }}</div>
                </div>

                <div
                  v-if="currentOption.download_url"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="currentOption.download_url" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    @click="() => handleDeleteFile()"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td
                style="background: #e8e8ea9f"
                colspan="2"
                v-if="currentOption"
              >
                <b-button
                  @click="() => handleSaveQuotation()"
                  v-if="editMode"
                  variant="dark-primary"
                  class="d-flex align-items-center"
                >
                  <i
                    class="bx bx-plus"
                    style="font-size: 1rem; margin-right: 10px"
                  />
                  SALVAR
                </b-button>
              </td>
            </tr>
          </table>
        </b-col>

        <b-col cols="12" v-if="item.tipo_tabela === 6">
          <table style="margin-top: 2rem" class="table">
            <tr>
              <th></th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  '#556ee6'
                };`"
              >
                <b-button
                  size="sm"
                  variant="light"
                  class="float-left"
                  style="background-color: #eff2f7"
                  :disabled="!hasPrevOption"
                  @click="handlePrevOption()"
                  v-if="currentOption"
                >
                  <i class="fas fa-arrow-left" />
                </b-button>

                <div class="d-flex align-items-center justify-content-between">
                  <div />

                  <span>ATUAL</span>

                  <b-button
                    pill
                    variant="dark-primary"
                    class="d-flex justify-content-center align-items-center"
                    style="
                      width: 2rem;
                      height: 2rem;
                      border: none;
                      border-radius: 50%;
                    "
                    @click="handleAddOption()"
                    v-if="pageNumber === 1"
                  >
                    <i class="bx bx-plus" style="font-size: 1rem" />
                  </b-button>
                </div>
              </th>

              <th
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id &&
                  '#556ee6'
                };`"
                v-if="currentOption"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <EditButton
                    :active="true"
                    @click.native="() => handleEditQuotation()"
                    v-if="pageNumber === 1 && !editMode"
                  />
                  <div v-else />

                  <span style="margin-right: 1rem">
                    OPÇÃO {{ currentOptionIndex + 1 }}
                  </span>

                  <div class="d-flex align-items-center">
                    <TrashButton
                      :click="() => handleDeleteOption()"
                      :active="true"
                      v-if="pageNumber === 1"
                    />
                    <span v-else />

                    <b-button
                      size="sm"
                      variant="light"
                      class="float-right ml-3"
                      style="background-color: #eff2f7"
                      :disabled="!hasNextOption"
                      @click="handleNextOption()"
                    >
                      <i class="fas fa-arrow-right" />
                    </b-button>
                  </div>
                </div>
              </th>
            </tr>

            <tr>
              <td>
                <span>1. Nome do bem</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[0].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.nome_bem"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.nome_bem }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>2. Tipo</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[1].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.tipo"
                  :options="dreamTypeOptions"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.tipo }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>3. Valor do bem</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[2].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_bem"
                  v-if="editMode"
                />
                <span v-else> R$ {{ currentOption.valor_bem | decimal }} </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>4. Valor de entrada</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[3].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_entrada"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.valor_entrada | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>5. Parcela mensal</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                R$
                {{
                  item.diagnostico_produto_item_tabela_itens[4].valor_cliente
                    | decimal
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <money
                  class="form-control"
                  v-bind="moneyMask"
                  v-model="currentOption.valor_mensal"
                  v-if="editMode"
                />
                <span v-else>
                  R$ {{ currentOption.valor_mensal | decimal }}
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>6. Para quando</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[5].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-select
                  v-model="currentOption.para_quando"
                  :options="dreamYearOptions"
                  v-if="!item.aguardando_resposta"
                />
                <span v-else>{{ currentOption.para_quando }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>7. Validade da proposta</span>
              </td>

              <td style="background: #e8e8ea9f; color: #495057">
                {{
                  item.diagnostico_produto_item_tabela_itens[6].valor_cliente
                }}
              </td>

              <td
                :style="`background: #e8e8ea9f; color: ${
                  currentOption &&
                  currentOption.diagnostico_produto_item_opcao_id &&
                  currentOption.diagnostico_produto_item_opcao_id ===
                    item.opcao_selecionada_id
                    ? '#1D954B'
                    : '#495057'
                };`"
                v-if="currentOption"
              >
                <b-form-input
                  type="text"
                  v-model="currentOption.validade_proposta"
                  v-if="editMode"
                />
                <span v-else>{{ currentOption.validade_proposta }}</span>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" />

              <td style="background: #e8e8ea9f" v-if="currentOption">
                <div>
                  <b-form-file
                    size="sm"
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    @input="
                      () => handleFileUpload(currentOption, currentOptionIndex)
                    "
                    v-model="currentOption.file"
                  />

                  <div>{{ currentOption.nome_original }}</div>
                </div>

                <div
                  v-if="currentOption.download_url"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="currentOption.download_url" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    @click="() => handleDeleteFile()"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </td>
            </tr>

            <tr>
              <td></td>

              <td style="background: #e8e8ea9f" colspan="2" />

              <td
                style="background: #e8e8ea9f"
                colspan="2"
                v-if="currentOption"
              >
                <b-button
                  @click="() => handleSaveQuotation()"
                  v-if="editMode"
                  variant="dark-primary"
                  class="d-flex align-items-center"
                >
                  <i
                    class="bx bx-plus"
                    style="font-size: 1rem; margin-right: 10px"
                  />
                  SALVAR
                </b-button>
              </td>
            </tr>
          </table>
        </b-col>

        <ContactDataTable
          :diagnosis="diagnosis"
          v-if="
            [2, 5, 6].includes(diagnosisProduct.produto_diagnostico_id) ||
            ['Seguro patrimonial'].includes(item.grupo) ||
            (item.id_campo === 14 &&
              diagnosisProduct.produto_diagnostico_id !== 3)
          "
        />

        <ContactDataHealthTable
          :diagnosis="diagnosis"
          v-if="diagnosisProduct.produto_diagnostico_id === 7"
        />

        <ConsultantDataTable
          :item="item"
          v-if="[2, 5, 6].includes(diagnosisProduct.produto_diagnostico_id)"
        />

        <b-col cols="12" v-if="pageNumber === 1 && item.tipo_tabela === 7">
          <div class="font-weight-bold mb-3 h5">
            Despesa com estudos no exterior
          </div>

          <table class="table">
            <tr class="bg-secondary text-white">
              <th class="text-center">Valor mensal</th>
              <th class="text-center">Quantidade de anos</th>
              <th class="text-center">Ano realização</th>
            </tr>

            <tr
              v-for="tableItem in item.diagnostico_produto_item_tabela_itens"
              :key="tableItem.diagnostico_produto_item_tabela_item_id"
            >
              <td>R$ {{ tableItem.intercambio_valor | decimal }}</td>
              <td>{{ tableItem.intercambio_tempo }} anos</td>
              <td>
                {{ tableItem.ano_intercambio }}
              </td>
            </tr>
          </table>
        </b-col>

        <b-col
          cols="12"
          v-if="
            pageNumber === 1 &&
            item.id_campo !== 14 &&
            item.grupo !== 'Câmbio' &&
            item.grupo !== 'Real estate' &&
            item.titulo !== 'Consórcio' &&
            item.grupo !== 'Seguro patrimonial' &&
            item.grupo !== 'Direcionamento ao especialista'
          "
        >
          <b-button
            @click="handleEditQuotation()"
            v-if="![1, 3, 5, 6].includes(item.tipo_tabela)"
            variant="primary"
            class="d-flex align-items-center"
          >
            <i
              class="bx bx-pencil"
              style="font-size: 1rem; margin-right: 10px"
            />
            EDITAR
          </b-button>

          <div class="d-flex align-items-between">
            <b-button
              variant="dark-primary"
              class="d-flex align-items-center"
              @click="() => handleSaveQuotation()"
              v-if="editMode && ![1, 3, 5, 6].includes(item.tipo_tabela)"
            >
              <i
                class="bx bx-plus"
                style="font-size: 1rem; margin-right: 10px"
              />
              SALVAR
            </b-button>
            <div v-else />

            <b-button
              @click="() => handleCancel(item)"
              v-if="editMode"
              variant="danger"
              class="d-flex align-items-center ml-2"
            >
              <i class="bx bx-x" style="font-size: 1rem; margin-right: 10px" />
              Cancelar
            </b-button>
          </div>
        </b-col>

        <b-col cols="12" v-if="pageNumber === 2">
          <b-button
            v-if="item.resposta_cliente === 1"
            style="background: #1d954b; width: 100%"
            class="d-flex align-items-center justify-content-center"
          >
            APROVADA
          </b-button>

          <b-button
            v-if="item.resposta_cliente === 3"
            style="background: #ff001d; width: 100%"
            class="d-flex align-items-center justify-content-center"
          >
            RECUSADA
          </b-button>
        </b-col>
      </b-col>

      <InputsDownloads
        :diagnosis="diagnosis"
        :item="item"
        v-if="pageNumber === 4"
      />

      <b-col
        cols="12"
        class="d-flex justify-content-end align-items-center mb-3"
      >
        <b-button
          variant="primary"
          class="mt-2 mr-2"
          @click="() => openQuotationToolsModal()"
          v-if="
            pageNumber === 1 && diagnosisProduct.produto_diagnostico_id === 1
          "
        >
          Ferramentas para cotação
        </b-button>

        <b-button-group
          class="mt-2 mr-2"
          style="padding: 6px 0"
          v-if="
            item.diagnostico_comentario &&
            item.diagnostico_comentario.comentario
          "
          @click="() => viewBrokerComment()"
        >
          <b-button
            style="
              background: var(--blue-indigo);
              border-color: var(--blue-indigo);
            "
          >
            <i class="fas fa-eye" style="font-size: 1rem" />
          </b-button>

          <b-button style="background: var(--blue); border-color: var(--blue)">
            COMENTÁRIO DO CORRETOR
          </b-button>
        </b-button-group>

        <ButtonEditComment
          icon="plus"
          :title="`${
            item.diagnostico_comentario_especialista &&
            item.diagnostico_comentario_especialista.comentario
              ? 'EDITAR'
              : 'ADICIONAR'
          } SEU COMENTÁRIO`"
          class="mt-2"
          :openModalEditComment="() => openModalAddEditCommentSpecialist()"
        />
      </b-col>

      <b-col
        class="d-flex justify-content-between align-items-center mb-3"
        v-if="pageNumber === 2"
      >
        <b-button style="background: transparent; border: none">
          <span
            style="color: var(--primary); font-weight: 500; font-size: 14px"
          >
            Resposta do cliente
          </span>
        </b-button>

        <b-form-select
          :style="`width: 30%; border-color: ${
            !item.resposta_cliente
              ? 'var(--blue)'
              : item.resposta_cliente == 1
              ? 'var(--green-later-leaf)'
              : [2, 4].includes(item.resposta_cliente)
              ? 'var(--yellow-light)'
              : item.resposta_cliente == 3 && 'var(--red-light)'
          }; color: ${
            !item.resposta_cliente
              ? 'var(--blue)'
              : item.resposta_cliente == 1
              ? 'var(--green-dark)'
              : [2, 4].includes(item.resposta_cliente)
              ? 'var(--crete)'
              : item.resposta_cliente == 3 && 'var(--roof-terracotta)'
          }; background-color: ${
            !item.resposta_cliente
              ? 'var(--white)'
              : item.resposta_cliente == 1
              ? 'var(--green-light)'
              : [2, 4].includes(item.resposta_cliente)
              ? 'var(--yellow-light-2)'
              : item.resposta_cliente == 3 && 'var(--pink-light)'
          }`"
          v-model="item.resposta_cliente"
          :options="statusStudy"
          @change="updateCustomerResponse()"
        />
      </b-col>

      <div
        v-if="itemIndex !== 0"
        style="
          position: absolute;
          height: 3px;
          width: 100%;
          background: #5190d0;
        "
      />
    </div>

    <ModalViewComment
      title="Comentário do corretor"
      :isOpen="isOpenModalViewComment"
      :diagnosisComment="item.diagnostico_comentario"
      @closeModal="closeModalViewComment()"
    />

    <ModalAddEditCommentSpecialist
      :isOpen="isOpenModalAddEditCommentSpecialist"
      :data="modalDataSpecialist"
      @onSaveComment="
        (diagnosisComment) => onSaveCommentSpecialist(diagnosisComment)
      "
      @closeModal="closeModalAddEditCommentSpecialist()"
    />

    <ModalQuotationTools
      :isOpen="isOpenQuotationToolsModal"
      @closeModal="closeQuotationToolsModal()"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import { Money } from 'v-money';
import { mapGetters } from 'vuex';

import { cloneObject } from '@/helpers';
import {
  periodoCoberturaMorte,
  periodoCoberturaVida,
  periodoCoberturaInvalidez,
  acomodacaoOptions,
  tipoCoberturaOptions,
  stateOptions,
  tipoPessoaSaudeOptions,
  statusStudy,
  formaConstrucaoOptions,
  indiceCorrecaoOptions,
  tipoBemOptions,
  dreamTypeOptions,
  dreamYearOptions
} from '@/constants/options';

import TrashButton from '@/components/common/Buttons/TrashButton.vue';
import EditButton from '@/components/common/Buttons/EditButton.vue';
import ButtonEditComment from '@/components/Buttons/ButtonEditComment';
import InputsDownloads from './InputsDownloads.vue';
import ContactDataTable from '@/components/common/ContactDataTable';
import ContactDataHealthTable from '@/components/common/ContactDataHealthTable';
import ConsultantDataTable from '@/components/common/ConsultantDataTable';

import ModalAddEditCommentSpecialist from '@/components/common/Modals/ModalAddEditCommentSpecialist';
import ModalViewComment from '@/components/common/Modals/ModalViewComment';
import ModalQuotationTools from '@/components/common/Modals/ModalQuotationTools';

import {
  uploadProductItemOption,
  upload2ProductItemOption,
  updateDiagnosisProductItem,
  deleteOptionFile,
  deleteOptionFile2,
  createProductItemOption,
  updateProductItemOption,
  deleteProductItemOption
} from '@/services/requests/diagnosis';

Vue.use(VueMask);

export default {
  name: 'ProductItem',
  components: {
    TrashButton,
    EditButton,
    ButtonEditComment,
    Money,
    InputsDownloads,
    ContactDataTable,
    ContactDataHealthTable,
    ConsultantDataTable,
    ModalAddEditCommentSpecialist,
    ModalViewComment,
    ModalQuotationTools
  },
  props: {
    pageNumber: Number,
    diagnosis: Object,
    item: Object,
    itemIndex: Number,
    group: Object,
    diagnosisProduct: Object
  },
  data() {
    return {
      editMode: false,
      optionsBackup: null,
      currentOptionIndex: 0,
      periodoCoberturaMorte,
      periodoCoberturaVida,
      periodoCoberturaInvalidez,
      acomodacaoOptions,
      tipoCoberturaOptions,
      stateOptions,
      tipoPessoaSaudeOptions,
      statusStudy,
      formaConstrucaoOptions,
      indiceCorrecaoOptions,
      tipoBemOptions,
      dreamTypeOptions,
      dreamYearOptions,
      modalDataSpecialist: null,
      isOpenModalAddEditCommentSpecialist: false,
      isOpenModalViewComment: false,
      checkingItems: false,
      isOpenQuotationToolsModal: false
    };
  },
  methods: {
    async handleFileUpload(option) {
      if (!option.file) return;

      const formData = new FormData();
      formData.append('file', option.file);

      const optionId = option.diagnostico_produto_item_opcao_id;

      uploadProductItemOption(optionId, option.file)
        .then((res) => {
          const optionIndex = this.item.diagnostico_produto_item_opcoes.findIndex(
            (option) => optionId === option.diagnostico_produto_item_opcao_id
          );

          const updatedOption = {
            ...cloneObject(option),
            ...res.data.result.data
          };

          this.$set(
            this.item.diagnostico_produto_item_opcoes,
            optionIndex,
            updatedOption
          );
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    async handleFile2Upload(option) {
      if (!option.file_2) return;

      const formData = new FormData();
      formData.append('file', option.file_2);

      const optionId = option.diagnostico_produto_item_opcao_id;

      upload2ProductItemOption(optionId, option.file_2)
        .then((res) => {
          const optionIndex = this.item.diagnostico_produto_item_opcoes.findIndex(
            (option) => optionId === option.diagnostico_produto_item_opcao_id
          );

          const updatedOption = {
            ...cloneObject(option),
            ...res.data.result.data
          };

          this.$set(
            this.item.diagnostico_produto_item_opcoes,
            optionIndex,
            updatedOption
          );
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleAddOption() {
      const option = {
        diagnostico_produto_item_opcao_id: null,
        seguradora: null,
        capital_segurado: this.item.sugeridos,
        solucao: null,
        periodo_cobertura: null,
        periodo_pagamento: null,
        premio_mensal: 0,
        premio_anual: 0,
        seguradora_sugerido: null,
        capital_segurado_sugerido: this.item.sugeridos,
        solucao_sugerido: null,
        periodo_cobertura_sugerido: null,
        periodo_pagamento_sugerido: null,
        premio_mensal_sugerido: 0,
        premio_anual_sugerido: 0
      };

      // if (this.item.id_campo === 1) {
      //   option.seguradora_sugerido = null;
      //   option.capital_segurado_sugerido = this.item.valor_necessario;
      //   option.solucao_sugerido = null;
      //   option.periodo_cobertura_sugerido = null;
      //   option.periodo_pagamento_sugerido = null;
      //   option.premio_mensal_sugerido = 0;
      //   option.premio_anual_sugerido = 0;

      //   option.fun_seguradora = null;
      //   option.fun_capital_segurado = this.itemFuneral
      //     ? this.itemFuneral.sugeridos
      //     : 0;
      //   option.fun_solucao = null;
      //   option.fun_periodo_cobertura = null;
      //   option.fun_periodo_pagamento = null;
      //   option.fun_premio_mensal = 0;
      //   option.fun_premio_anual = 0;

      //   option.fun_seguradora_sugerido = null;
      //   option.fun_capital_segurado_sugerido = this.itemFuneral
      //     ? this.itemFuneral.valor_necessario
      //     : 0;
      //   option.fun_solucao_sugerido = null;
      //   option.fun_periodo_cobertura_sugerido = null;
      //   option.fun_periodo_pagamento_sugerido = null;
      //   option.fun_premio_mensal_sugerido = 0;
      //   option.fun_premio_anual_sugerido = 0;
      // } else
      if (this.item.tipo_tabela === 1) {
        option.seguradora_sugerido = null;
        option.capital_segurado = this.item.sugeridos;
        option.solucao = null;
        option.periodo_cobertura = null;
        option.periodo_pagamento = null;
        option.premio_mensal = 0;
        option.premio_anual = 0;

        option.seguradora_sugerido = null;
        option.capital_segurado_sugerido = this.item.valor_necessario;
        option.solucao_sugerido = null;
        option.periodo_cobertura_sugerido = null;
        option.periodo_pagamento_sugerido = null;
        option.premio_mensal_sugerido = 0;
        option.premio_anual_sugerido = 0;
      } else if (this.item.tipo_tabela === 3) {
        option.tipo_pessoa = null;
        option.operadora = null;
        option.estado = null;
        option.cidade = null;
        option.nome_produto = null;
        option.tipo_cobertura = null;
        option.acomodacao = null;
        option.possui_preferencia = null;
        option.valor_mensal = 0;
        option.valor_reembolso = 0;
        option.coparticipacao = false;
        option.validade_proposta = '';

        option.dep_tipo_pessoa = null;
        option.dep_operadora = null;
        option.dep_estado = null;
        option.dep_cidade = null;
        option.dep_nome_produto = null;
        option.dep_tipo_cobertura = null;
        option.dep_acomodacao = null;
        option.dep_possui_preferencia = null;
        option.dep_valor_mensal = 0;
        option.dep_valor_reembolso = 0;
        option.dep_coparticipacao = false;
        option.dep_validade_proposta = '';
      } else if (this.item.tipo_tabela === 5) {
        option.nome_bem = this.item.diagnostico_produto_item_tabela_itens[0].valor_cliente;
        option.tipo = this.item.diagnostico_produto_item_tabela_itens[1].valor_cliente;
        option.valor_bem = this.item.diagnostico_produto_item_tabela_itens[2].valor_cliente;
        option.valor_financiado = this.item.diagnostico_produto_item_tabela_itens[3].valor_cliente;
        option.valor_mensal = this.item.diagnostico_produto_item_tabela_itens[4].valor_cliente;
        option.parcelas_restantes = this.item.diagnostico_produto_item_tabela_itens[5].valor_cliente;
        option.forma_construcao = this.item.diagnostico_produto_item_tabela_itens[6].valor_cliente;
        option.taxa_correcao = this.item.diagnostico_produto_item_tabela_itens[7].valor_cliente;
        option.operadora = this.item.diagnostico_produto_item_tabela_itens[8].valor_cliente;
        option.valor_entrada = this.item.diagnostico_produto_item_tabela_itens[9].valor_cliente;
        option.validade_proposta = '';
      } else if (this.item.tipo_tabela === 6) {
        option.nome_bem = this.item.diagnostico_produto_item_tabela_itens[0].valor_cliente;
        option.tipo = this.item.diagnostico_produto_item_tabela_itens[1].valor_cliente;
        option.valor_bem = this.item.diagnostico_produto_item_tabela_itens[2].valor_cliente;
        option.valor_entrada = this.item.diagnostico_produto_item_tabela_itens[3].valor_cliente;
        option.valor_mensal = this.item.diagnostico_produto_item_tabela_itens[4].valor_cliente;
        option.para_quando = this.item.diagnostico_produto_item_tabela_itens[5].valor_cliente;
        option.validade_proposta = '';
      }

      option.diagnostico_produto_item_id = this.item.diagnostico_produto_item_id;

      createProductItemOption(option)
        .then((res) => {
          const updatedOption = res.data.result.data;

          this.item.diagnostico_produto_item_opcoes.push(updatedOption);
          this.currentOptionIndex =
            this.item.diagnostico_produto_item_opcoes.length - 1;
        })
        .catch(() => {
          alert('Houve um erro ao salvar as opções');
        });
    },

    handleEditQuotation() {
      this.editMode = true;
      this.optionsBackup = cloneObject(
        this.item.diagnostico_produto_item_opcoes
      );
    },

    handleSaveQuotation() {
      const optionId = this.currentOption.diagnostico_produto_item_opcao_id;

      updateProductItemOption(optionId, this.currentOption)
        .then((res) => {
          const updatedOption = res.data.result.data;

          this.$set(
            this.item.diagnostico_produto_item_opcoes,
            this.currentOptionIndex,
            updatedOption
          );
          this.editMode = false;
        })
        .catch(() => {
          alert('Houve um erro ao salvar as opções');
        });
    },

    handleDeleteOption() {
      const optionId = this.currentOption.diagnostico_produto_item_opcao_id;

      deleteProductItemOption(optionId)
        .then(() => {
          const options = cloneObject(
            this.item.diagnostico_produto_item_opcoes
          );
          const index = this.currentOptionIndex;

          options.splice(index, 1);

          this.$set(this.item, 'diagnostico_produto_item_opcoes', options);

          if (this.item.diagnostico_produto_item_opcoes[index - 1]) {
            this.currentOptionIndex = index - 1;
          } else if (this.item.diagnostico_produto_item_opcoes[index]) {
            this.currentOptionIndex = index;
          } else {
            this.currentOptionIndex = null;
          }

          if (this.item.opcao_selecionada_id === optionId) {
            this.item.opcao_selecionada_id = null;
          }
          if (this.item.fun_opcao_selecionada_id === optionId) {
            this.item.fun_opcao_selecionada_id = null;
          }
        })
        .catch(() => {
          alert('Houve um erro ao salvar as opções');
        });
    },

    handleCancel(item) {
      this.editMode = false;
      item.diagnostico_produto_item_opcoes = [];
    },

    verifyItens() {
      this.checkingItems = true;
    },

    handleChangePeriodoCobertura(value) {
      if (![2, 3].includes(this.item.id_campo)) return;

      this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex
      ].periodo_pagamento = value;
    },

    handleChangePeriodoCoberturaSugerido(value) {
      if (![2, 3].includes(this.item.id_campo)) return;

      this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex
      ].periodo_pagamento_sugerido = value;
    },

    handleChangeFunPeriodoCobertura(value) {
      if (![2, 3].includes(this.item.id_campo)) return;

      this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex
      ].fun_periodo_pagamento = value;
    },

    handleChangeFunPeriodoCoberturaSugerido(value) {
      if (![2, 3].includes(this.item.id_campo)) return;

      this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex
      ].fun_periodo_pagamento_sugerido = value;
    },

    handlePrevOption() {
      this.currentOptionIndex--;
    },

    handleNextOption() {
      this.currentOptionIndex++;
    },

    handleDeleteFile() {
      if (!confirm('Deseja realmente excluir este arquivo?'));

      deleteOptionFile(this.currentOption.diagnostico_produto_item_opcao_id)
        .then((res) => {
          this.item.aguardando_resposta = true;
          this.$set(
            this.item.diagnostico_produto_item_opcoes,
            this.currentOptionIndex,
            res.data.result.data
          );
          this.editMode = false;
        })
        .catch((error) => {
          let errorMessage = 'Houve um erro ao salvar as opções';

          if (error.response) {
            if (
              error.response.data.statusCode &&
              error.response.data.statusCode !== 200
            ) {
              errorMessage = error.response.data.statusMessage;
            }

            if (
              error.response.data.message &&
              !error.response.data.message.diagnostico_produto_item_opcoes
            ) {
              errorMessage = 'Todos os valores são obrigatórios';
            }
          }

          alert(errorMessage);
        });
    },

    handleDeleteFile2() {
      if (!confirm('Deseja realmente excluir este arquivo?'));

      deleteOptionFile2(this.currentOption.diagnostico_produto_item_opcao_id)
        .then((res) => {
          this.item.aguardando_resposta = true;
          this.$set(
            this.item.diagnostico_produto_item_opcoes,
            this.currentOptionIndex,
            res.data.result.data
          );
          this.editMode = false;
        })
        .catch((error) => {
          let errorMessage = 'Houve um erro ao salvar as opções';

          if (error.response) {
            if (
              error.response.data.statusCode &&
              error.response.data.statusCode !== 200
            ) {
              errorMessage = error.response.data.statusMessage;
            }

            if (
              error.response.data.message &&
              !error.response.data.message.diagnostico_produto_item_opcoes
            ) {
              errorMessage = 'Todos os valores são obrigatórios';
            }
          }

          alert(errorMessage);
        });
    },
    openModalAddEditCommentSpecialist() {
      this.modalDataSpecialist = this.item
        .diagnostico_comentario_especialista || {
        diagnostico_comentario_id: null,
        diagnostico_produto_item_id: this.item.diagnostico_produto_item_id,
        comentario: null,
        download_url: null
      };

      this.isOpenModalAddEditCommentSpecialist = true;
    },
    onSaveCommentSpecialist(diagnosisComment) {
      this.$set(
        this.item,
        'diagnostico_comentario_especialista',
        diagnosisComment
      );
      this.closeModalAddEditCommentSpecialist();
    },
    closeModalAddEditCommentSpecialist() {
      this.isOpenModalAddEditCommentSpecialist = false;
    },
    viewBrokerComment() {
      this.isOpenModalViewComment = true;
    },
    closeModalViewComment() {
      this.isOpenModalViewComment = false;
    },
    updateCustomerResponse() {
      const data = cloneObject(this.item);

      updateDiagnosisProductItem(this.item.diagnostico_produto_item_id, data)
        .then(() => {})
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },
    handleChangeOpcaoSelecionada(necessario = false) {
      const data = cloneObject(this.item);
      data.opcao_selecionada_necessario = necessario;

      updateDiagnosisProductItem(this.item.diagnostico_produto_item_id, data)
        .then(() => {})
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },
    openQuotationToolsModal() {
      this.isOpenQuotationToolsModal = true;
    },
    closeQuotationToolsModal() {
      this.isOpenQuotationToolsModal = false;
    }
  },

  computed: {
    ...mapGetters('diagnosis', ['insurersOptions']),
    isGestaoRiscoMorte() {
      return [1, 2, 3].includes(this.item.id_campo);
    },
    isInvalidez() {
      return [4, 5].includes(this.item.id_campo);
    },
    currentOption() {
      const currentOption = this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex
      ];

      if (!currentOption) return null;

      return currentOption;
    },
    hasPrevOption() {
      return !!this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex - 1
      ];
    },
    hasNextOption() {
      return !!this.item.diagnostico_produto_item_opcoes[
        this.currentOptionIndex + 1
      ];
    },
    saudeDependente() {
      return this.diagnosis.pessoa.pessoa_conjuge.saude.possui_seguro_plano
        ? this.diagnosis.pessoa.pessoa_conjuge.saude
        : null;
    },
    seguroVida() {
      return this.diagnosis.pessoa.seguro_itens.find(
        (seguro) => seguro.tipo_seguro === 'Vida Inteira'
      );
    },
    seguroFuneral() {
      return this.diagnosis.pessoa.seguro_itens.find(
        (seguro) => seguro.tipo_seguro === 'Assistência ou auxílio funeral'
      );
    },
    seguroPrincipal() {
      if (this.item.seguros.length === 0) return null;

      if (this.item.id_campo === 2) {
        return this.item.seguros.find((seguro) => seguro.nome === 'Temporário');
      }

      return this.item.seguros[0];
    },
    itemFuneral() {
      return this.item.diagnostico_produto_item_tabela_itens.find(
        (item) => item.itens === 'Auxílio Funeral'
      );
    },
    optionsOpcaoSelecionada() {
      return [
        { value: null, text: 'Selecione uma opção' },
        ...this.item.diagnostico_produto_item_opcoes.map((option, index) => ({
          value: option.diagnostico_produto_item_opcao_id,
          text: `Opção ${index + 1}`
        }))
      ];
    },
    isProdutoPatrimonio() {
      return this.diagnosisProduct.produto_diagnostico_id === 3;
    },
    faixasIdadeVidasSaude() {
      const faixasIdadeVidasSaude = [
        {
          title: '0 a 18',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 0,
          fim: 18
        },
        {
          title: '19 a 23',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 19,
          fim: 23
        },
        {
          title: '24 a 28',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 24,
          fim: 28
        },
        {
          title: '29 a 33',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 29,
          fim: 33
        },
        {
          title: '34 a 43',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 34,
          fim: 43
        },
        {
          title: '44 a 48',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 44,
          fim: 48
        },
        {
          title: '49 a 53',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 49,
          fim: 53
        },
        {
          title: '54 a 58',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 54,
          fim: 58
        },
        {
          title: '59 ou mais',
          quantidadeTitular: 0,
          quantidadeConjuge: 0,
          inicio: 59
        }
      ];

      if (this.diagnosis.pessoa.saude.possui_seguro_plano) {
        const idade = Number(this.diagnosis.pessoa.pessoa_idade);

        faixasIdadeVidasSaude.forEach((faixa) => {
          if (idade >= faixa.inicio && idade <= faixa.fim) {
            faixa.quantidadeTitular++;
          }
        });
      }

      if (this.diagnosis.pessoa.pessoa_conjuge.saude.possui_seguro_plano) {
        const idade = Number(
          this.diagnosis.pessoa.pessoa_conjuge.idade_conjuge
        );

        faixasIdadeVidasSaude.forEach((faixa) => {
          if (idade >= faixa.inicio && idade <= faixa.fim) {
            faixa.quantidadeConjuge++;
          }
        });
      }

      this.diagnosis.pessoa.dependentes.forEach((dependent) => {
        if (
          dependent.saude.possui_seguro_plano &&
          dependent.saude.incluso_valor_titular
        ) {
          const idade = Number(dependent.idade_dependente);

          faixasIdadeVidasSaude.forEach((faixa) => {
            if (
              idade >= faixa.inicio &&
              (idade <= faixa.fim || faixa.fim === null)
            ) {
              faixa.quantidadeTitular++;
            }
          });
        }

        if (
          dependent.saude.possui_seguro_plano &&
          dependent.saude.incluso_valor_conjuge
        ) {
          const idade = Number(dependent.idade_dependente);

          faixasIdadeVidasSaude.forEach((faixa) => {
            if (
              idade >= faixa.inicio &&
              (idade <= faixa.fim || faixa.fim === null)
            ) {
              faixa.quantidadeConjuge++;
            }
          });
        }
      });

      return faixasIdadeVidasSaude;
    },
    customerAlreadyHasProduct() {
      return (
        this.pageNumber === 1 &&
        this.item.seguros &&
        this.item.seguros.some((insurance) => insurance.download_url)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.container-left {
  background-color: #f7f7fa;
  padding: 1rem;
  width: 50%;
  margin-top: 1rem;
}

@media only screen and (max-width: 1425px) {
  .container-left {
    background-color: #f7f7fa;
    padding: 1rem;
    width: 100%;
  }
}

.description {
  color: #74788d;
}

.optionsAlreadyRegister {
  color: #556ee6;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1rem;
}

.approved {
  color: #1d954b;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1rem;
}

.refused {
  color: #ff001d;
  font-weight: 500;
  font-size: 14px;
  margin-top: 1rem;
}

.box-alert-client-already-has-product {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background: #eeeef0;
  width: 25rem;
  padding: 0.6rem;

  margin: 1rem 0;

  i {
    font-size: 2rem;
  }

  span {
  }
}
</style>
