var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"div-icon-title"},[(
        _vm.diagnosisProduct.produto_diagnostico.icone ===
        'fas fa-heartbeat icon-heart'
      )?_c('span',[_c('IconLife',{attrs:{"color":_vm.diagnosisProduct.produto_diagnostico.cor}})],1):_vm._e(),(
        _vm.diagnosisProduct.produto_diagnostico.icone ===
        'fas fa-medkit icon-health'
      )?_c('span',[_c('IconHealth',{attrs:{"color":_vm.diagnosisProduct.produto_diagnostico.cor}})],1):_vm._e(),(
        _vm.diagnosisProduct.produto_diagnostico.icone ===
        'bx bx-cloud icon-cloud'
      )?_c('span',[_c('IconDream',{attrs:{"color":_vm.diagnosisProduct.produto_diagnostico.cor}})],1):_vm._e(),(
        _vm.diagnosisProduct.produto_diagnostico.icone ===
        'bx bx-check-shield icon-cloud'
      )?_c('span',[_c('IconProtection',{attrs:{"color":_vm.diagnosisProduct.produto_diagnostico.cor}})],1):_vm._e(),(
        _vm.diagnosisProduct.produto_diagnostico.icone ===
        'bx bx-credit-card icon-cloud'
      )?_c('span',[_c('IconMoney',{attrs:{"color":_vm.diagnosisProduct.produto_diagnostico.cor}})],1):_vm._e(),(
        _vm.diagnosisProduct.produto_diagnostico.icone ===
        'bx bx-dollar icon-cloud'
      )?_c('span',[_c('IconExchange',{attrs:{"color":_vm.diagnosisProduct.produto_diagnostico.cor}})],1):_vm._e(),(
        _vm.diagnosisProduct.produto_diagnostico.icone === 'bx bx-home icon-cloud'
      )?_c('span',[_c('IconRealState',{attrs:{"color":_vm.diagnosisProduct.produto_diagnostico.cor}})],1):_vm._e(),_c('strong',{style:({
        color: _vm.diagnosisProduct.produto_diagnostico.cor
      })},[_vm._v(" "+_vm._s(_vm.diagnosisProduct.nome_produto)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }