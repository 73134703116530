<template>
  <div style="width: 100%">
    <div class="div-icon-title">
      <span
        v-if="
          diagnosisProduct.produto_diagnostico.icone ===
          'fas fa-heartbeat icon-heart'
        "
        ><IconLife :color="diagnosisProduct.produto_diagnostico.cor"
      /></span>

      <span
        v-if="
          diagnosisProduct.produto_diagnostico.icone ===
          'fas fa-medkit icon-health'
        "
      >
        <IconHealth :color="diagnosisProduct.produto_diagnostico.cor" />
      </span>

      <span
        v-if="
          diagnosisProduct.produto_diagnostico.icone ===
          'bx bx-cloud icon-cloud'
        "
        ><IconDream :color="diagnosisProduct.produto_diagnostico.cor"
      /></span>

      <span
        v-if="
          diagnosisProduct.produto_diagnostico.icone ===
          'bx bx-check-shield icon-cloud'
        "
        ><IconProtection :color="diagnosisProduct.produto_diagnostico.cor"
      /></span>

      <span
        v-if="
          diagnosisProduct.produto_diagnostico.icone ===
          'bx bx-credit-card icon-cloud'
        "
        ><IconMoney :color="diagnosisProduct.produto_diagnostico.cor"
      /></span>

      <span
        v-if="
          diagnosisProduct.produto_diagnostico.icone ===
          'bx bx-dollar icon-cloud'
        "
        ><IconExchange :color="diagnosisProduct.produto_diagnostico.cor"
      /></span>

      <span
        v-if="
          diagnosisProduct.produto_diagnostico.icone === 'bx bx-home icon-cloud'
        "
        ><IconRealState :color="diagnosisProduct.produto_diagnostico.cor"
      /></span>

      <strong
        :style="{
          color: diagnosisProduct.produto_diagnostico.cor
        }"
      >
        {{ diagnosisProduct.nome_produto }}
      </strong>
    </div>
  </div>
</template>

<script>
import IconLife from '@/assets/images/icons/products/IconLife';
import IconHealth from '@/assets/images/icons/products/IconHealth';
import IconDream from '@/assets/images/icons/products/IconDream';
import IconProtection from '@/assets/images/icons/products/IconProtection';
import IconMoney from '@/assets/images/icons/products/IconMoney';
import IconExchange from '@/assets/images/icons/products/IconExchange';
import IconRealState from '@/assets/images/icons/products/IconRealState';

export default {
  name: 'ProductTabTitle',
  components: {
    IconLife,
    IconHealth,
    IconDream,
    IconProtection,
    IconMoney,
    IconExchange,
    IconRealState
  },
  props: {
    diagnosisProduct: Object,
    isTabActive: Boolean
  },
  computed: {
    selectedDiagnosticProducts() {
      if (!this.diagnosis) return [];

      return this.diagnosis.diagnostico_produtos.filter(
        (product) => product.selecionado
      );
    }
  }
};
</script>
