<template>
  <div>
    <svg :height="height" :width="width">
      <circle :cx="cx" :cy="cy" :r="r" fill="var(--portage)" />
      <text
        :font-size="fontSize"
        font-family="Arial, Helvetica, sans-serif"
        fill="var(--royal-blue)"
        text-anchor="middle"
        :x="x"
        :y="y"
      >
        {{ getFirstLettersOfName(name).toUpperCase() }}
      </text>
    </svg>
  </div>
</template>

<script>
import firstLettersOfNames from '@/mixins/first-letters-of-names';

export default {
  props: {
    height: {
      default: '54',
      type: String
    },
    width: {
      default: '54',
      type: String
    },
    cx: {
      default: '27',
      type: String
    },
    cy: {
      default: '27',
      type: String
    },
    r: {
      default: '27',
      type: String
    },
    fillCircle: {
      default: 'var(--royal-blue)',
      type: String
    },
    fontSize: {
      default: '27',
      type: String
    },
    fillText: {
      default: 'var(--blue)',
      type: String
    },
    x: {
      default: '27',
      type: String
    },
    y: {
      default: '36',
      type: String
    },
    name: String
  },
  mixins: [firstLettersOfNames]
};
</script>

<style></style>
