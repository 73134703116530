<template>
  <div>
    <div class="accordion" role="tablist" style="margin-top: 1rem">
      <b-card no-body class="mb-1">
        <div
          v-if="pageNumber === 4 && groups.length === 0"
          class="text-center mt-2"
        >
          <p style="font-size: 1.1rem">Nenhum item aprovado</p>
        </div>

        <div v-for="(group, index) in groups" :key="`group-${index}`">
          <div>
            <b-card-header
              header-tag="header"
              class="px-1 pt-1 pb-0"
              role="tab"
            >
              <b-button
                :style="`
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: none;
                text-transform: uppercase;
              `"
                block
                variant="secondary"
                v-b-toggle="`accordion-item-${index}`"
              >
                {{ group.name }}
                <span class="when-opened">
                  <i class="bx bx-chevron-up" style="font-size: 1.5rem"></i>
                </span>
                <span class="when-closed">
                  <i class="bx bx-chevron-down" style="font-size: 1.5rem" />
                </span>
              </b-button>
            </b-card-header>

            <b-collapse :id="`accordion-item-${index}`" role="tabpanel" visible>
              <b-card-body class="pt-0">
                <div
                  v-for="(item, itemIndex) in group.items"
                  :key="`item-${item.diagnostico_produto_item_id}`"
                >
                  <ProductItem
                    :pageNumber="pageNumber"
                    :item="item"
                    :diagnosis="diagnosis"
                    :itemIndex="itemIndex"
                    :group="group"
                    :diagnosisProduct="data"
                  />
                </div>
              </b-card-body>
            </b-collapse>
          </div>
        </div>
      </b-card>

      <div v-for="(group, index) in groups" :key="`group-${index}`">
        <div v-if="group.name === 'Liberdade financeira'">
          <b-card v-once no-body class="mb-1">
            <b-card-header
              style="border-radius: none"
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border: none;
                "
                block
                v-b-toggle.accordion-2
                variant="secondary"
              >
                POSIÇÃO PATRIMONIAL
                <span class="when-opened">
                  <i class="bx bx-chevron-up" style="font-size: 1.5rem"></i>
                </span>
                <span class="when-closed">
                  <i class="bx bx-chevron-down" style="font-size: 1.5rem" />
                </span>
              </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" role="tabpanel">
              <b-card-body style="background: var(--background-light-grey)">
                <b-row style="margin-bottom: 1rem">
                  <b-col
                    class="d-flex justify-content-center"
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <Graphic1 :diagnosis="diagnosis" />
                  </b-col>

                  <b-col
                    id="chart_div"
                    class="d-flex justify-content-center"
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <Graphic2 :diagnosis="diagnosis" />
                  </b-col>

                  <b-col
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <EmergencyReserve :diagnosis="diagnosis" />
                  </b-col>
                </b-row>

                <LiquidInvestments :diagnosis="diagnosis" />

                <InvestmentProjection :diagnosis="diagnosis" />
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card v-once no-body class="mb-1">
            <b-card-header
              style="border-radius: none"
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border: none;
                "
                block
                v-b-toggle.accordion-3
                variant="secondary"
              >
                LIBERDADE FINANCEIRA
                <span class="when-opened">
                  <i class="bx bx-chevron-up" style="font-size: 1.5rem"></i>
                </span>
                <span class="when-closed">
                  <i class="bx bx-chevron-down" style="font-size: 1.5rem" />
                </span>
              </b-button>
            </b-card-header>

            <b-collapse id="accordion-3" role="tabpanel">
              <b-card-body style="background: var(--background-light-grey)">
                <b-row style="margin-bottom: 1rem">
                  <b-col
                    style="
                      widht: 100%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <Pension :diagnosis="diagnosis" />
                  </b-col>
                </b-row>

                <b-row style="margin-bottom: 1rem">
                  <b-col
                    style="
                      widht: 45%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <FinancialFreedom :diagnosis="diagnosis" />
                  </b-col>

                  <b-col
                    style="
                      widht: 45%;
                      background: var(--white);
                      margin: 5px;
                      border-radius: 5px;
                      padding: 2rem;
                    "
                  >
                    <CurrentAssetsVsFinancialFreedom :diagnosis="diagnosis" />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>

    <slot name="after"></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as loggedAsTypes from '@/constants/loggedAsTypes';

import ProductItem from './ProductItem';
import Graphic1 from '@/components/common/Graphics/Graphic1';
import Graphic2 from '@/components/common/Graphics/Graphic2';
import EmergencyReserve from '@/components/common/Graphics/EmergencyReserve';
import Pension from '@/components/common/Graphics/Pension';
import LiquidInvestments from '@/components/common/Graphics/LiquidInvestments';
import InvestmentProjection from '@/components/common/Graphics/InvestmentProjection';
import FinancialFreedom from '@/components/common/Graphics/FinancialFreedom';
import CurrentAssetsVsFinancialFreedom from '@/components/common/Graphics/CurrentAssetsVsFinancialFreedom';

export default {
  name: 'ProductTabContent',
  components: {
    ProductItem,
    Graphic1,
    Graphic2,
    EmergencyReserve,
    Pension,
    LiquidInvestments,
    InvestmentProjection,
    FinancialFreedom,
    CurrentAssetsVsFinancialFreedom
  },
  props: {
    pageNumber: Number,
    diagnosis: Object,
    data: Object
  },
  computed: {
    ...mapState('auth', ['user', 'loggedAs']),
    groups() {
      const groups = {};

      this.data.diagnostico_produto_itens
        .filter((item) => item.fazer_cotacao)
        .forEach((item) => {
          if (this.pageNumber === 4 && item.resposta_cliente !== 1) {
            return;
          }

          if (!groups[item.grupo]) {
            groups[item.grupo] = {
              name: item.grupo,
              items: []
            };
          }

          groups[item.grupo].items.push(item);
        });

      return Object.values(groups);
    },

    loggedAsBroker() {
      return this.loggedAs === loggedAsTypes.BROKER;
    },
    loggedAsSpecialist() {
      return this.loggedAs === loggedAsTypes.SPECIALIST;
    }
  }
};
</script>
