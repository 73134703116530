<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button
        block
        v-b-toggle="'accordion-information-for-specialist'"
        class="text-left rounded-0"
        variant="secondary"
        style="font-size: 1.25rem"
      >
        <i class="fas fa-user-circle"></i>
        INFORMAÇÕES PARA ESPECIALISTA

        <i class="bx bxs-chevron-up when-closed float-right mt-1"></i>
        <i class="bx bxs-chevron-down when-opened float-right mt-1"></i>
      </b-button>
    </b-card-header>

    <b-card-body style="background-color: var(--background-light-grey)">
      <b-collapse
        id="accordion-information-for-specialist"
        visible
        role="tabpanel"
      >
        <b-row>
          <b-col lg="4">
            <b-table-simple class="vertical-table">
              <b-tbody>
                <b-tr>
                  <b-th>Nome completo</b-th>
                  <b-td>{{
                    diagnosis.pessoa.pessoa_nome
                      ? diagnosis.pessoa.pessoa_nome
                      : ' - '
                  }}</b-td>
                </b-tr>

                <b-tr>
                  <b-th>Data de nascimento</b-th>
                  <b-td>{{
                    diagnosis.pessoa.pessoa_data_nascimento
                      ? moment
                          .utc(diagnosis.pessoa.pessoa_data_nascimento)
                          .format('DD/MM/YYYY')
                      : ' - '
                  }}</b-td>
                </b-tr>

                <b-tr>
                  <b-th>Sexo</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.pessoa_sexo">
                      {{ diagnosis.pessoa.pessoa_sexo | genderName }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>CPF</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.pessoa_cpf">
                      {{ diagnosis.pessoa.pessoa_cpf }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>Idade</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.pessoa_idade">
                      {{ diagnosis.pessoa.pessoa_idade }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>

          <b-col lg="4">
            <b-table-simple class="vertical-table">
              <b-tbody>
                <b-tr>
                  <b-th>Estado civil</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.pessoa_estado_civil">
                      {{
                        diagnosis.pessoa.pessoa_estado_civil | maritalStatusName
                      }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>E-mail</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.pessoa_email">
                      {{ diagnosis.pessoa.pessoa_email }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>Celular</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.pessoa_celular">
                      {{ diagnosis.pessoa.pessoa_celular }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>Profissão</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.pessoa_profissao">
                      {{ diagnosis.pessoa.pessoa_profissao }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>Fumante ?</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.pessoa_saude.fumante">
                      {{ diagnosis.pessoa.pessoa_saude.fumante | statusSmoke }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>

          <b-col lg="4">
            <b-table-simple class="vertical-table">
              <b-tbody>
                <b-tr>
                  <b-th>Estado</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.endereco.endereco_estado">
                      {{ diagnosis.pessoa.endereco.endereco_estado }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>Cidade</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.endereco.endereco_cidade">
                      {{ diagnosis.pessoa.endereco.endereco_cidade }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>Renda média mensal</b-th>
                  <b-td>
                    <span
                      v-if="
                        !!diagnosis.indicadores_financeiros.renda_media_mensal
                          .value
                      "
                    >
                      R$
                      {{
                        diagnosis.indicadores_financeiros.renda_media_mensal
                          .value | decimal
                      }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>Despesa média mensal</b-th>
                  <b-td>
                    <span
                      v-if="
                        !!diagnosis.indicadores_financeiros.despesa_media_mensal
                          .value
                      "
                    >
                      R$
                      {{
                        diagnosis.indicadores_financeiros.despesa_media_mensal
                          .value | decimal
                      }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>Poupança média mensal</b-th>
                  <b-td>
                    <span
                      v-if="
                        !!diagnosis.indicadores_financeiros
                          .media_poupanca_mensal.value
                      "
                    >
                      R$
                      {{
                        diagnosis.indicadores_financeiros.media_poupanca_mensal
                          .value | decimal
                      }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>

          <b-col cols="12">
            <b-row class="mx-0">
              <b-col
                cols="12"
                class="p-2 mt-3"
                style="
                  background-color: var(--light-grey-2);
                  border: 1px solid var(--border-grey);
                "
              >
                <strong>Seguradoras vinculadas ao corretor responsável:</strong>
              </b-col>

              <b-col
                cols="12"
                class="p-2 text-center"
                style="border: 1px solid var(--border-grey)"
                v-if="insurers.length === 0"
              >
                O corretor não tem nenhuma seguradora vinculada
              </b-col>

              <b-col
                md="6"
                lg="4"
                class="p-2"
                style="border: 1px solid var(--border-grey)"
                v-for="(insurer, index) in insurers"
                :key="index"
              >
                {{ insurer }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'InformationForSpecialist',
  props: {
    diagnosis: Object
  },
  data() {
    return {
      moment
    };
  },
  computed: {
    ...mapState('diagnosis', ['insurers'])
  }
};
</script>

<style lang="scss" scoped>
.vertical-table th {
  background-color: var(--light-grey-2);
}

.vertical-table th,
.vertical-table td {
  border: 1px solid var(--border-grey);
}
</style>
