<template>
  <main>
    <ModalAddComment
      idColumn="diagnostico_produto_item_id"
      urlResource="diagnostico-produto-item"
      urlAppend="/comentario-especialista"
      commentColumn="comentario"
      @onSuccess="() => loadDiagnosis()"
    />
    <ModalViewCommentOld title="Comentário do corretor" />
    <ModalConsultantObservations
      :isOpen="isOpenModalConsultantObservations"
      @closeModal="closeModalConsultantObservations()"
      :person="diagnosis ? diagnosis.pessoa : null"
    />

    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <b-breadcrumb :items="breadcrumb" class="m-0" />
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mb-3">
      <b-spinner
        v-if="loading"
        variant="secondary"
        type="grow"
        label="Spinning"
      />
    </div>

    <b-row v-if="!loading">
      <b-col>
        <b-card v-if="diagnosis">
          <b-row class="p-3 d-flex align-items-center justify-content-between">
            <div>
              <h4>
                {{ pageNumber }}. {{ pageNameUppercase }} -
                <span class="text-primary">
                  {{ diagnosis.pessoa.pessoa_nome }}
                </span>
              </h4>

              <div>
                <p>
                  Atividade iniciada em
                  {{ diagnosis.data_criacao | formatDate }} - Preencha os campos
                  das tabelas com as cotações.
                </p>
              </div>
            </div>

            <div>
              <div class="d-flex align-items-center" style="font-size: 11px">
                <AvatarLetters
                  height="27"
                  width="27"
                  cx="13.5"
                  cy="13.5"
                  r="13.5"
                  fillCircle="var(--royal-blue)"
                  fontSize="13.5"
                  fillText="var(--blue)"
                  x="13.5"
                  y="18"
                  :name="diagnosis.usuario.pessoa.pessoa_nome"
                />

                <div
                  style="margin-left: 10px; margin-right: 10rem; color: #74788d"
                >
                  <div>Consultor responsável:</div>
                  <strong>{{ diagnosis.usuario.pessoa.pessoa_nome }}</strong>
                </div>
              </div>
            </div>
          </b-row>

          <div class="mb-5">
            <b-button
              class="d-flex align-items-center ml-auto"
              @click="openModalConsultantObservations()"
            >
              <i class="bx bx-comment-detail mr-2" style="font-size: 1rem" />
              Observações do consultor
            </b-button>
          </div>

          <InformationForSpecialist :diagnosis="diagnosis" />

          <GeneralProfile :diagnosis="diagnosis" />

          <b-row>
            <b-col>
              <b-card>
                <div style="width: 100%">
                  <b-card no-body style="margin: 0 -20px" v-if="diagnosis">
                    <b-tabs content-class="mt-6" fill v-model="currentTab">
                      <b-tab
                        no-body
                        title-item-class="tab-product"
                        :key="diagnosisProduct.diagnostico_produto_id"
                        v-for="(
                          diagnosisProduct, index
                        ) in selectedDiagnosticProducts"
                      >
                        <template #title>
                          <ProductTabTitle
                            :diagnosisProduct="diagnosisProduct"
                            :isTabActive="currentTab !== index"
                          />
                        </template>

                        <div class="mt-3">
                          <div
                            class="d-flex align-items-center justify-content-end"
                            style="font-size: 11px"
                            v-if="diagnosisProduct.especialista"
                          >
                            <AvatarLetters
                              height="27"
                              width="27"
                              cx="13.5"
                              cy="13.5"
                              r="13.5"
                              fillCircle="var(--royal-blue)"
                              fontSize="13.5"
                              fillText="var(--blue)"
                              x="13.5"
                              y="18"
                              :name="
                                diagnosisProduct.especialista.pessoa.pessoa_nome
                              "
                            />
                            <div
                              class="d-flex flex-column"
                              style="
                                margin-left: 10px;
                                color: var(--footer-color);
                              "
                            >
                              <span>Especialista responsável:</span>
                              <strong>
                                {{
                                  diagnosisProduct.especialista.pessoa
                                    .pessoa_nome
                                }}
                              </strong>
                            </div>
                          </div>

                          <div v-else class="text-right">
                            Sem especialista responsável
                            <b-button
                              class="ml-2"
                              variant="primary"
                              @click="
                                assignSpecialistToProduct(
                                  diagnosisProduct,
                                  index
                                )
                              "
                            >
                              Vincular-se ao produto
                            </b-button>
                          </div>
                        </div>

                        <ProductTabContent
                          :pageNumber="pageNumber"
                          :diagnosis="diagnosis"
                          :data="diagnosisProduct"
                        />
                      </b-tab>
                    </b-tabs>

                    <div class="d-flex mt-5">
                      <b-button
                        style="margin: auto; width: 13rem"
                        variant="primary"
                        @click="completeQuotation()"
                      >
                        CONCLUIR COTAÇÃO
                      </b-button>
                    </div>
                  </b-card>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import InformationForSpecialist from '@/components/SpecialistSteps/InformationForSpecialist.vue';
import GeneralProfile from '@/components/SpecialistSteps/GeneralProfile.vue';
import AvatarLetters from '@/components/common/AvatarLetters';
import ModalAddComment from '@/components/common/Modals/ModalAddComment';
import ModalViewCommentOld from '@/components/common/Modals/ModalViewCommentOld';
import ProductTabTitle from './ProductTabTitle';
import ProductTabContent from './ProductTabContent';
import ModalConsultantObservations from '@/components/common/Modals/ModalConsultantObservations';

import {
  requestDiagnosisSpecialist,
  updatePhaseSpecialist,
  updateDiagnosisProductSpecialist
} from '@/services/requests/diagnosis';

import createToastMixin from '@/mixins/create-toast-mixin';

export default {
  mixins: [createToastMixin],
  components: {
    AvatarLetters,
    GeneralProfile,
    ModalAddComment,
    ModalViewCommentOld,
    ProductTabTitle,
    ProductTabContent,
    InformationForSpecialist,
    ModalConsultantObservations
  },
  props: {
    pageName: {
      type: String,
      required: true
    },
    pageNumber: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      diagnosis: null,
      currentTab: 0,
      isOpenModalConsultantObservations: false
    };
  },
  methods: {
    loadDiagnosis() {
      this.loading = true;

      requestDiagnosisSpecialist(this.diagnosisId)
        .then((res) => {
          this.loading = false;
          this.diagnosis = res.data.result.data;
        })
        .catch((error) => {
          this.loading = false;
          this.$store.state.modal.isOpenModalErrorRequest = true;
          this.$store.state.modal.errorMessageModal =
            error.response.data.message;
        });
    },
    completeQuotation() {
      const diagnosisId = this.diagnosis.diagnostico_id;

      const actualRouterName = this.$route.name;

      const data = {
        estagio_id: null
      };
      let toRouteName = null;

      switch (actualRouterName) {
        case 'ProposalAndQuotation':
          toRouteName = 'AwaitingConsultant';
          data.estagio_id = 2;
          break;
        case 'CheckProposalResponse':
          toRouteName = 'ProposalExecution';
          data.estagio_id = 3;
          break;
        case 'InputProposals':
          toRouteName = 'SupplierApproval';
          data.estagio_id = 5;
          break;
      }

      updatePhaseSpecialist(diagnosisId, data)
        .then((res) => {
          const diagnosisId = res.data.result.data.diagnostico_id;

          this.$router.push({ name: toRouteName, params: { diagnosisId } });
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    openModalConsultantObservations() {
      this.isOpenModalConsultantObservations = true;
    },

    closeModalConsultantObservations() {
      this.isOpenModalConsultantObservations = false;
    },

    assignSpecialistToProduct(diagnosisProduct, index) {
      const confirmation = confirm(
        'Você tem certeza que deseja vincular-se ao produto?'
      );
      if (!confirmation) return;

      updateDiagnosisProductSpecialist(diagnosisProduct.diagnostico_produto_id)
        .then((res) => {
          if (res.status !== 200) throw new Error();

          this.$set(
            this.diagnosis.diagnostico_produtos[index],
            'especialista',
            res.data.result.data
          );
          this.createToast(res.data.statusMessage, 'Sucesso!', 'success');
        })
        .catch((e) => {
          let message =
            'Houve um erro ao ao vincular-se como especialista do produto.';

          if (e.response && e.response.data.statusMessage) {
            message = e.response.data.statusMessage;
          }

          this.createToast(message, 'Atenção!', 'danger');
        });
    }
  },
  computed: {
    diagnosisId() {
      return this.$route.params.diagnosisId;
    },
    pageNameUppercase() {
      return this.pageName.toUpperCase();
    },
    selectedDiagnosticProducts() {
      if (!this.diagnosis) return [];

      return this.diagnosis.diagnostico_produtos.filter(
        (product) => product.selecionado
      );
    },
    breadcrumb() {
      const breadcrumb = [
        {
          text: 'Inicial',
          href: '/'
        },
        {
          text: 'Painel de atividades inicial',
          href: '/activity-panel'
        }
      ];

      if (this.pageNumber > 1) {
        breadcrumb.push({
          text: 'Preparar cotação',
          href: `/activity-panel/proposal-quotation/${this.diagnosisId}`
        });
      }

      if (this.pageNumber > 2) {
        breadcrumb.push({
          text: 'Verificar resposta da proposta',
          href: `/activity-panel/check-proposal-response/${this.diagnosisId}`
        });
      }

      if (this.pageNumber > 3) {
        breadcrumb.push({
          text: 'Efetivação da proposta',
          href: `/activity-panel/proposal-execution/${this.diagnosisId}`
        });
      }

      breadcrumb.push({
        text: `${this.pageName} - ${
          this.diagnosis ? this.diagnosis.pessoa.pessoa_nome : ''
        }`,
        active: true
      });

      return breadcrumb;
    }
  },
  mounted() {
    this.loadDiagnosis();
    this.$store.dispatch('diagnosis/loadInsurers');
  }
};
</script>

<style lang="scss">
.nav-fill .tab-product .nav-link {
  .div-icon-title {
    display: flex;
    align-items: center;
    padding: 12px;
    height: 3rem;

    i:first-child {
      font-size: 1.3rem;
    }

    strong {
      font-size: 12px;
      margin-left: 5%;
    }
  }
}
.nav-fill .tab-product .nav-link:not(.active) {
  .div-icon-title {
    padding-left: 10px;
  }
}
</style>
