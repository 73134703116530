import { render, staticRenderFns } from "./InformationForSpecialist.vue?vue&type=template&id=6bcd63d0&scoped=true&"
import script from "./InformationForSpecialist.vue?vue&type=script&lang=js&"
export * from "./InformationForSpecialist.vue?vue&type=script&lang=js&"
import style0 from "./InformationForSpecialist.vue?vue&type=style&index=0&id=6bcd63d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bcd63d0",
  null
  
)

export default component.exports