<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button
        block
        v-b-toggle="'accordion-general-profile'"
        class="text-left rounded-0"
        variant="secondary"
        style="font-size: 1.25rem"
      >
        <i class="fas fa-user-circle"></i>
        PERFIL GERAL

        <i class="bx bxs-chevron-up when-closed float-right mt-1"></i>
        <i class="bx bxs-chevron-down when-opened float-right mt-1"></i>
      </b-button>
    </b-card-header>

    <b-card-body style="background-color: var(--background-light-grey)">
      <b-collapse id="accordion-general-profile" visible role="tabpanel">
        <b-row>
          <b-col cols="12" md="6" lg="3">
            <b-table-simple class="vertical-table">
              <b-tbody>
                <b-tr>
                  <b-th colspan="2">
                    <i class="fas fa-user-circle"></i>
                    <span class="ml-2">PERFIL PESSOAL</span>
                  </b-th>
                </b-tr>

                <b-tr>
                  <b-th>Nome completo</b-th>
                  <b-td>{{
                    diagnosis.pessoa.pessoa_nome
                      ? diagnosis.pessoa.pessoa_nome
                      : ' - '
                  }}</b-td>
                </b-tr>

                <b-tr>
                  <b-th>Idade</b-th>
                  <b-td>{{
                    diagnosis.pessoa.pessoa_idade
                      ? diagnosis.pessoa.pessoa_idade
                      : ' - '
                  }}</b-td>
                </b-tr>

                <b-tr>
                  <b-th>Estado civil</b-th>
                  <b-td>
                    <span v-if="!!diagnosis.pessoa.pessoa_estado_civil">
                      {{
                        diagnosis.pessoa.pessoa_estado_civil | maritalStatusName
                      }}
                    </span>
                    <span v-else> - </span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-table-simple class="vertical-table">
              <b-tbody>
                <b-tr>
                  <b-th colspan="2">
                    <i class="fas fa-users"></i>
                    <span class="ml-2">ESTRUTURA FAMILIAR</span>
                  </b-th>
                </b-tr>

                <b-tr>
                  <b-th>Cônjuge</b-th>
                  <b-td>
                    <span
                      v-if="
                        diagnosis.pessoa.pessoa_conjuge &&
                        diagnosis.pessoa.pessoa_conjuge.nome_conjuge
                      "
                    >
                      {{ diagnosis.pessoa.pessoa_conjuge.nome_conjuge }}
                    </span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>

                <b-tr
                  v-for="(dependent, index) in diagnosis.pessoa.dependentes"
                  :key="dependent.dependente_id"
                >
                  <b-th>Dependente {{ index + 1 }}</b-th>
                  <b-td>{{ dependent.nome_dependente }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-table-simple class="vertical-table">
              <b-tbody>
                <b-tr>
                  <b-th colspan="2">
                    <i class="fas fa-cloud"></i>
                    <span class="ml-2">SONHOS</span>
                  </b-th>
                </b-tr>

                <b-tr v-if="diagnosis.pessoa.sonhos.length === 0">
                  <b-th>-</b-th>
                  <b-td>-</b-td>
                </b-tr>

                <b-tr
                  v-for="dream in diagnosis.pessoa.sonhos"
                  :key="dream.sonho_id"
                >
                  <b-th>
                    {{ dream.nome }}
                  </b-th>
                  <b-td> R$ {{ dream.valor | decimal }} </b-td>
                </b-tr>

                <b-tr>
                  <b-td class="font-weight-bold border-right-0">Total</b-td>
                  <b-td class="border-left-0">
                    R$ {{ totalValueDream | decimal }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-table-simple class="vertical-table">
              <b-tbody>
                <b-tr>
                  <b-th colspan="2">
                    <i class="fas fa-wallet"></i>
                    <span class="ml-2">LIBERDADE FINANCEIRA</span>
                  </b-th>
                </b-tr>

                <b-tr>
                  <b-th>Idade p/ aposentadoria</b-th>
                  <b-td>
                    <span
                      v-if="
                        diagnosis.pessoa.pessoa_plano &&
                        diagnosis.pessoa.pessoa_plano
                          .plano_quantos_anos_liberdade_financeira
                      "
                    >
                      {{
                        diagnosis.pessoa.pessoa_plano
                          .plano_quantos_anos_liberdade_financeira
                      }}
                    </span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>Renda mensal desejada</b-th>
                  <b-td>
                    <span
                      v-if="
                        diagnosis.pessoa.pessoa_plano &&
                        diagnosis.pessoa.pessoa_plano
                          .plano_renda_desejada_liberdade_financeira
                      "
                    >
                      R$
                      {{
                        diagnosis.pessoa.pessoa_plano
                          .plano_renda_desejada_liberdade_financeira | decimal
                      }}
                    </span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>

                <b-tr>
                  <b-th>Expectativa de vida</b-th>
                  <b-td>
                    <span
                      v-if="
                        diagnosis.pessoa.pessoa_plano &&
                        diagnosis.pessoa.pessoa_plano
                          .plano_ate_quantos_anos_liberdade_financeira
                      "
                    >
                      {{
                        diagnosis.pessoa.pessoa_plano
                          .plano_ate_quantos_anos_liberdade_financeira
                      }}
                    </span>
                    <span v-else>-</span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: 'GeneralProfile',
  props: {
    diagnosis: Object
  },
  computed: {
    totalValueDream() {
      if (!this.diagnosis) return 0;

      return this.diagnosis.pessoa.sonhos.reduce(
        (total, dream) => total + dream.valor,
        0
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.vertical-table th {
  background-color: var(--light-grey-2);
}

.vertical-table th,
.vertical-table td {
  border: 1px solid var(--border-grey);
}
</style>
