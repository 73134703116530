export default {
  methods: {
    getFirstLettersOfName(fullName) {
      let name = String(fullName);
      let positionFirstSpace = name.indexOf(' ');
      let firstLetter = name.charAt(0);
      const firstLetterSecondName = name.substring(
        positionFirstSpace + 1,
        positionFirstSpace + 2
      );

      let join = `${firstLetter}${firstLetterSecondName}`;

      return join;
    }
  }
};
