<template>
  <b-modal v-model="isOpenModal" ok-only>
    <template #modal-header>
      <div style="margin: 0 auto">
        <h5 style="color: var(--grey)">Ferramentas para cotação</h5>
      </div>
    </template>

    <template #modal-footer="{ ok }">
      <b-button size="md" variant="success" @click="ok()">OK</b-button>
    </template>

    <div class="p-3" style="border-color: var(--light-grey) !important">
      <p v-for="toolLink in toolsLinks" :key="toolLink.link">
        {{ toolLink.name }} -
        <a target="_blank" class="text-dark-primary" :href="toolLink.link">
          {{ toolLink.link }}
        </a>
      </p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalQuotationTools',
  props: {
    isOpen: Boolean,
    diagnosisComment: Object
  },
  data() {
    return {
      toolsLinks: [
        {
          name: 'Centauro',
          link: 'https://centauro-online.centauro-on.com.br/#/login'
        },
        {
          name: 'MAG',
          link: 'https://digital.mag.com.br/'
        },
        {
          name: 'Prudential',
          link: 'https://identity.prudentialdobrasil.com.br/'
        },
        {
          name: 'Omint',
          link: 'https://pcp.omintseguros.com.br/'
        },
        {
          name: 'Icatu',
          link: 'https://portal.icatuseguros.com.br/corretor'
        },
        {
          name: 'Metlife',
          link: 'https://cotadoronline.metlife.com.br/'
        },
        {
          name: 'Mapfre',
          link: 'https://cotacao.bienvivir.mapfre.com.br/'
        }
      ]
    };
  },
  methods: {
    ok() {
      this.cancel();
    },
    cancel() {
      this.$emit('closeModal');
    }
  },
  computed: {
    isOpenModal: {
      get() {
        return this.isOpen;
      },
      set(value) {
        if (!value) {
          this.cancel();
        }
      }
    }
  }
};
</script>
